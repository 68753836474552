import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstacionamentoService {

  base_url = `${environment.baseUrl}`;
  constructor(private http: HttpClient) { }

  getEstacionamentos(dataInicio: string, dataFim: string, page: number, perPage: number, placa?: string) {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    let link = `${this.base_url}/v1/admin/estacionamentos`
    if(localStorage.getItem('idCidade')){
      link += `?cidade=${localStorage.getItem('idCidade')}`
    }
    if(dataInicio && dataFim){
      link += `&inicio=${dataInicio}&final=${dataFim}`
    }
    if(page){
      link += `&page=${page}&perPage=${perPage}&pagination=true`;
    }
    if(placa){
      link += `&placa=${placa}`
    }
    return new Promise(async (resolve, reject) => {
      this.http
        .get(link, httpOptions).subscribe(
          async (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err)
          });
    });
  }


  getEstacionamentosQuadros(dateBegin?: string,dateEnd?: string):Observable<any>{
    const accessToken = localStorage.getItem('token');
    const idCidade = localStorage.getItem('idCidade');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return this.http.get<any>(`${this.base_url}/v1/admin/historico-estacionamentos-quadros?cidade=${idCidade}&inicio=${dateBegin}&final=${dateEnd}`, httpOptions).pipe(take(1))
  }

}
