import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ILogin } from '../Models/session.model';
import { Router } from '@angular/router';
import * as crypto from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  base_url = `${environment.baseUrl}`;

  constructor(private http: HttpClient, private router: Router) { }

  login(user: string, password: string, salt: string) {
    const headers = new HttpHeaders({ 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With' });
    const body: ILogin = {
      username: user,
      password: password,
      salt: salt
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .post(`${this.base_url}/v1/admin/login`, body, { headers: headers }).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  newUser(type: number, data: any) {
    const url = type == 5 || type == 6 || type == 7 ? '/v1/admin/create-user-sistema' : 
                type === 2 ? '/v1/admin/create-operador-pos' : 
                type === 1 ? '/v1/admin/create-agente-fiscalizacao' : 
                type === 4 ? '/v1/admin/create-canal-fisico-venda' : 
                '/v1/admin/clientes';
  
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
  
    // create senha hash using crypto-js
    let senha = data.senha;
    let vetorSenha = crypto.enc.Utf8.parse(senha);
    let hashSenha = crypto.SHA512(vetorSenha).toString(crypto.enc.Base64);
    data.senha = hashSenha;
  
    return new Promise(async (resolve, reject) => {
      this.http
        .post(`${this.base_url}${url}`, data, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  isAuthenticated() {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    return token && refreshToken;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  refreshToken(refresh_token: string) {
    const headers = new HttpHeaders({ 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With' });
    const rt = { refresh_token }
    return this.http
      .post<any>(`${this.base_url}/v1/refreshToken`, rt, { headers: headers });
  }

  otpRequest(username: string) {
    const headers = new HttpHeaders({ 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With' });
    return this.http
      .get<any>(`${this.base_url}/v1/get-otp?username=${username}`, { headers: headers });
  }

  otpValidate(data: any) {
    const body = data;
    let senha = data.newPassword;
    let vetorSenha = crypto.enc.Utf8.parse(senha);
    let hashSenha = crypto.SHA512(vetorSenha).toString(crypto.enc.Base64);
    data.newPassword = hashSenha;
    
    const headers = new HttpHeaders({ 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With' });
    
    return this.http.post<any>(`${this.base_url}/v1/validate-otp`, body, { headers: headers });
  }
}
