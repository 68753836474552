<section class="position-relative pt-5">
  <!-- Type 1: Status, Type 0: Sem status -->
  <div class="table-div">
    <div class="filter-div p-3 mt-3 pb-0 d-flex">
      <div class="flex">
        <span class="p-input-icon-left mr-3">
          <input style="min-width: 255px;" [(ngModel)]="searchText" pInputText type="text" placeholder="Pesquisar por identificador" field="search" />
        </span>
        <p-button icon="pi pi-search" [rounded]="true"
         severity="success" styleClass="p-button-search"  (click)="getCredito()" />

      </div>
      <!-- Adicionar Usuário -->
      <p-button (onClick)="export()" label="Exportar (.csv)" class="add-button ms-auto"></p-button>
    </div>
    <!-- <app-generic-table *ngIf="creditosData.length > 0" -->
    <app-generic-table *ngIf="creditosData && creditosData.length > 0 && showComponent; else empty"
      [item]="{colunas: colunas, data: creditosData, type: 1, config: {disableSort: true, fields: fields, paginator: false}}"
      class="p-5 pb-0"></app-generic-table>
      <p-paginator (onPageChange)="changePage($event)" [first]="0" [rows]="perPage" [totalRecords]="totalItems"
      ></p-paginator>
  </div>
</section>

<ng-template #empty>
  <div class="empty-table">
    <p class="text-center">Nenhum registro encontrado</p>
  </div>
</ng-template>
