<section class="position-relative pt-5">
  <!-- Type 1: Status, Type 0: Sem status -->
  <div class="table-div">
    <div class="filter-div p-3 pb-0 px-1 mt-3 d-flex align-items-end mb-4">
      <div class="me-3">
        <b class="me-1 titleCalendar">De</b>
        <input type="date" [(ngModel)]="dataInicio" class="mt-2">
      </div>
      <div class="me-3">
        <b class="me-3 titleCalendar">Até</b>
        <input type="date" [(ngModel)]="dataFim" class="mt-2">
      </div>

      <div class="me-3 block xxl:inline-flex items-baseline ">
        <b class="me-3 titleFilter">Placa</b>
        <span class="p-input-icon-right mt-5 mb-2 md:mt-0 md:mb-0 w-full">
          <input [(ngModel)]="searchText" type="text"
            class="p-inputtext w-full mt-2 surface-border" placeholder="Digite" />
        </span>
      </div>

      <div class="me-3 block xxl:inline-flex items-baseline">
        <b class="me-2 titleFilter">Agente</b>
        <p-dropdown [options]="agentes" placeholder="Todos" [showClear]="true" [required]="false"
          [(ngModel)]="selectedAgentes" optionLabel="nome" optionValue="id" appendTo="body"></p-dropdown>

      </div>

      <div class="me-3 flex align-items-end">
        <button pButton pRipple label="Filtrar" (click)="getMultas()" icon="pi pi-filter"
          class="block font-bold white-space-nowrap p-button-outlined p-button-filter"></button>

      </div>
      <!-- Exportar -->
      <p-button (onClick)="export()" label="Exportar (.csv)" class="add-button ms-auto flex align-items-end"></p-button>
    </div>
    <!-- <app-generic-table *ngIf="creditosData.length > 0" -->
      <app-generic-table *ngIf="multasDataLimitadas && multasDataLimitadas.length > 0 && showComponent; else empty"
        [item]="{colunas: colunas, data: multasDataLimitadas, type: 1, config: {disableSort: true, fields: fields, paginator: false, columnClasses: columnClasses}}"
        class="p-5 pb-0"></app-generic-table>

    <p-paginator (onPageChange)="changePage($event)" [first]="0" [rows]="perPage" [totalRecords]="totalItems"
     ></p-paginator>
  </div>
</section>

<ng-template #empty>
  <div class="empty-table">
    <p class="text-center">Nenhum registro encontrado</p>
  </div>
</ng-template>
