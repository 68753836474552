import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CidadeService } from 'src/app/service/cidade.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-cidades',
  templateUrl: './cidades.component.html',
  styleUrls: ['./cidades.component.scss']
})
export class CidadesComponent implements OnInit {

  constructor(private _fb: FormBuilder, private readonly cityService: CidadeService, private messageService: MessageService, private loading: LoadingService) { }

  public addUserDiag: boolean = false;
  public userType: any;
  colunas: string[] = ["id", "Cidade", "Estado", "Valor Hora (Carro)", "Valor Hora (Moto)", "Período Mínimo", "Horários de Funcionamento", "Status"];

  cityData: any[] = [];

  timeDateInicio: any[] = [];

  timeDateFim: any[] = [];

  timeDateArray: any[] = [];

  public addCityForm: FormGroup = this._fb.group({
    estado: [null, [Validators.required]],
    horariosFuncionamento: [null],
    nome: [null, [Validators.required]],
    periodoMinimo: [null, [Validators.required]],
    status: [1],
    valorHoraCarro: [null, [Validators.required]],
    valorHoraMoto: [null, [Validators.required]]
  });

  public dias = [
    { name: 'Segunda' },
    { name: 'Terça' },
    { name: 'Quarta' },
    { name: 'Quinta' },
    { name: 'Sexta' }
  ];

  ngOnInit(): void {
    this.getCity();
  }

  getUserFiltro() {
    console.log(this.userType);
  }

  getCity() {
    this.loading.showLoading();
    this.cityData = [];
    this.cityService.getCidades().then((res: any) => {
      this.cityData = res;
      this.loading.hideLoading();
    }
    ).catch((err) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao carregar cidades!', detail: err });
      this.loading.hideLoading();
    });
  }

  updateCity(id: string, data: any) {
    this.loading.showLoading();
    this.cityService.updateCidades(id, data).then((res: any) => {
      this.messageService.add({ severity: 'success', summary: 'Cidade atualizada com sucesso!', detail: '' });
      this.loading.hideLoading();
    }).catch((err: any) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao atualizar cidade!', detail: err });
      this.loading.hideLoading();
    });
  }

  putTime() {
    for (const [index, iterator] of this.addCityForm.value.horariosFuncionamento.entries()) {
      this.timeDateArray.push({
        diaSemana: iterator,
        horaInicio: (this.timeDateInicio[index] as Date).toLocaleTimeString(),
        horaFim: (this.timeDateFim[index] as Date).toLocaleTimeString()
      });
    }
    this.addCityForm.get('horariosFuncionamento')?.setValue(this.timeDateArray);
  }

  addCity() {
    this.putTime();
    this.loading.showLoading();
    this.cityService.createCidade(this.addCityForm.value).then((res: any) => {
      this.messageService.add({ severity: 'success', summary: 'Cidade adicionada com sucesso!', detail: '' });
      this.loading.hideLoading();
      this.addCityForm.reset();
      this.getCity();
    }).catch((err: any) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao adicionar cidade!', detail: err });
      this.loading.hideLoading();
    });
  }
}
