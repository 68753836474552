import { ChangeDetectorRef, Component } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { LoadingService } from './shared/loading/loading.service.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SmartParking';
  items: MenuItem[] = [];

  constructor(private loading: LoadingService,
    private cdr: ChangeDetectorRef) { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    const focusableElements = document.querySelectorAll('.keyboard-focusable');
    focusableElements.forEach(element => {
      element.classList.remove('keyboard-focusable');
    });
  }
  
  loading$ = this.loading.loading$;
  ngOnInit() {
    this.items = [{
      label: 'File',
      items: [
        { label: 'New', icon: 'pi pi-fw pi-plus' },
        { label: 'Download', icon: 'pi pi-fw pi-download' }
      ]
    },
    {
      label: 'Edit',
      items: [
        { label: 'Add User', icon: 'pi pi-fw pi-user-plus' },
        { label: 'Remove User', icon: 'pi pi-fw pi-user-minus' }
      ]
    }];
  }
}
