import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/service/user.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';



@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsuariosComponent implements OnInit {
  filteredItems: any;
  showComponent: boolean = true;

  constructor(private _fb: FormBuilder,
    private messageService: MessageService,
    private readonly userService: UserService,
    private loading: LoadingService, public router: Router,
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef) { }

  public addUserDiag: boolean = false;
  public userType: any;
  colunas: any[] = ["Celular", "Código POS", "Data Registro", "Documento", "fcmToken", "Id", "Id Cidade", "Id Usuário", "Nome", "Sobrenome", "Status", "Tipo"];
  fields: string[] = [];
  addUserType: number;
  edit: boolean = false;
  updateValues: any;
  public postForm: any;
  searchText: string;
  rotas: string[] = [
    "Rota 1",
    "Rota 2",
    "Rota 3",
    "Rota 4",
    "Rota 5",
    "Rota 6",
    "Rota 7",
    "Rota 8",
    "Rota 9",
    "Rota 10",
    "Rota 11",
    "Rota 12",
    "Rota 13",
    "Rota 14",
    "Rota 15",
    "Rota 16",
    "Rota 17",
    "Rota 18",
    "Rota 19",
    "Rota 20"
  ];

  userData: any[] = [];

  public addUserForm: FormGroup = this._fb.group({
    nome: [null, [Validators.required]],
    username: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    senha: [null],
    confirmPassword: [null],
    celular: [null, [Validators.required]],
    tipo: 0,
    idCidade: [null]
    // createdAt: [new Date(), [Validators.required]],
    // status: [true, [Validators.required]]
  });

  public addUserForm2: FormGroup = this._fb.group({
    username: [null, [Validators.required]],
    codigoPOS: [null, [Validators.required]],
    nome: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    celular: [null, [Validators.required]],
    senha: [null],
    confirmPassword: [null],
    tipo: 2,
    idCidade: [null],
    rota: [null],
    vagaCarro: [null],
    vagaMoto: [null]
    // createdAt: [new Date(), [Validators.required]],
    // status: [true, [Validators.required]]
  });

  public addAgenteForm2: FormGroup = this._fb.group({
    username: [null, [Validators.required]],
    nome: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    celular: [null, [Validators.required]],
    senha: [null],
    confirmPassword: [null,],
    tipo: 1,
    idCidade: [null],
    codPrefeitura: [null, [Validators.required]],
    // createdAt: [new Date(), [Validators.required]],
    // status: [true, [Validators.required]]
  });

  public addUserForm3: FormGroup = this._fb.group({
    username: [null, [Validators.required]],
    codMac: [null, [Validators.required]],
    nome: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    celular: [null, [Validators.required]],
    senha: [null],
    confirmPassword: [null],
    documento: [null, [Validators.required]],
    cep: [null, [Validators.required]],
    place: [null, [Validators.required]],
    num: [null, [Validators.required]],
    complement: [null],
    neighborhood: [null, [Validators.required]],
    city: [null, [Validators.required]],
    state: [null, [Validators.required]],
    endereco: [null],
    idCidade: [null],
    tipo: 4
  });

  // public addUserForm: FormGroup = this._fb.group({
  //   name: [null, [Validators.required]],
  //   surName: [null, [Validators.required]],
  //   document: [null, [Validators.required]],
  //   city: [null, [Validators.required]],
  //   phone: [null, [Validators.required]],
  //   type: [null, [Validators.required]],
  //   createdAt: [new Date(), [Validators.required]],
  //   status: [true, [Validators.required]]
  // });

  ngOnInit(): void {
    this.getRoute();
    this.getUsers();
  }

  filterItems(searchText: string) {
    this.refresh();

    // Função auxiliar para remover acentos e pontuações
    const normalizeString = (str: string): string => {
      if(!str) return '';
      // Remove acentos
      let normalized = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      // Remove pontuações
      normalized = normalized.replace(/[^a-zA-Z0-9\s]/g, '');
      return normalized.toLowerCase();
    };

    const normalizedSearchText = normalizeString(searchText);

    this.filteredItems = this.userData.filter(item => {
      return Object.keys(item)
        .filter(key => key !== 'id')
        .some(key => normalizeString(item[key]?.toString()).includes(normalizedSearchText));
    });

    console.log("🚀 ~ file: usuarios.component.ts ~ UsuariosComponent ~ filterItems ~ this.filteredItems:", this.filteredItems)
  }


  console() {
    console.log(this.postForm);
  }

  refresh() {
    // Altera o valor da propriedade para recriar o componente
    this.showComponent = false;
    setTimeout(() => this.showComponent = true);
  }

  getRoute() {
    const routerState: any = this.router.routerState.snapshot.url;
    switch (routerState) {
      case '/sistema/usuarios/sistema':
        this.colunas = ["Código do usuário (login)", "Nome de Usuário", "E-mail", "Celular", "Perfil", "Data do Registro", "Situação"];
        this.fields = ["username", "nome", "email", "celular", "tipoUser", "createdAt", "status", "id"];
        this.addUserType = 0;
        this.postForm = this.addUserForm;
        break;
      case '/sistema/usuarios/monitores':
        this.colunas = ["Código do usuário (login)", "Código da Máquina", "Nome de Usuário", "E-mail", "Celular", "Data do Registro", "Rota", "Vaga Carro", "Vaga Moto", "Situação"];
        this.fields = ['username', 'codigoPOS', 'nome', 'email', 'celular', 'createdAt', "id", "rota", "vagaCarro", "vagaMoto", "status"];
        this.addUserType = 1;
        this.postForm = this.addUserForm2;
        break;
      case '/sistema/usuarios/fisc':
        this.colunas = ["Código na Prefeitura", "Código do usuário (login)", "Nome do Usuário", "E-mail", "Celular", "Data do Registro", "Situação"];
        this.fields = ['codPrefeitura', 'username', 'nome', 'email', 'celular', 'createdAt', 'status', "id"];
        this.addUserType = 2;
        this.postForm = this.addAgenteForm2;
        break;
      case '/sistema/usuarios/venda':
        this.colunas = ["Código do usuário (login)", "Código da Máquina", "Nome do Usuário", "E-mail", "CNPJ", "Celular", "Data do Registro", "Endereço", "Situação"];
        this.fields = ['username', 'codMac', 'nome', 'email', 'documento', 'celular', 'createdAt', 'endereco', 'status', "id"];
        // this.fields = ['id', 'codigoPOS', 'nome', 'email', 'celular', 'active', 'endereco', 'status'];
        this.addUserType = 3;
        this.postForm = this.addUserForm3;
        this.postForm.get('tipo').setValue(4);
        break;
      case '/sistema/usuarios/clientes':
        this.colunas = ["CPF/CNPJ", "Nome / Razão Social", "E-mail", "Celular", "Data do Registro", "Situação"];
        this.fields = ['username', 'nome', 'email', 'celular', 'createdAt', 'status', "id"];
        break;
      default:
        this.colunas = ["CPF", "Nome de Usuário", "E-mail", "Celular", "Perfil", "Dada do Registro", "Situação", "id"];
        break;
    }
    this.colunas = this.colunas.map((name, index) => ({
      name: name,
      id: index
    }));
  }

  getUsers(tipo?: string) {
    this.userData = [];
    this.loading.showLoading();
    this.userService.getUsers().then((res: any) => {
      this.userData = res;
      this.filteredItems = res;
      this.loading.hideLoading();
    }).catch((err) => {
      console.log(err)
      this.messageService.add({ severity: 'error', summary: 'Erro ao carregar usuários!', detail: err });
      this.loading.hideLoading();
    });
  }

  newUser() {
    var hasAllField = true;
    if (!this.postForm.get('rota')?.value) {
      hasAllField = false;
    }
    if (this.postForm.get('senha')?.value !== this.postForm.get('confirmPassword')?.value) {
      this.messageService.add({ severity: 'error', summary: 'Erro ao cadastrar usuário!', detail: 'As senhas não conferem' });
      return;
    }
    if (this.edit) {
      this.loading.showLoading();
      if ((this.postForm as FormGroup).get('senha')?.touched) {
        // Implementar
      }
      if(this.postForm.get('tipo')?.value == 4){
        // Avenida José Faria da Rocha, 234 Loja - Eldorado - Contagem/MG 32315-040
        this.postForm.get('endereco').setValue(`${this.postForm.get('place')?.value}, ${this.postForm.get('num')?.value} ${this.postForm.get('complement')?.value} - ${this.postForm.get('neighborhood')?.value} - ${this.postForm.get('city')?.value}/${this.postForm.get('state')?.value} ${this.postForm.get('cep')?.value}`);
      }
      this.userService.putUser(this.updateValues.id,  this.postForm.value).then(() => {
        this.addUserDiag = false;
        this.messageService.add({ severity: 'success', summary: 'Usuário atualizado com sucesso', detail: '' });
        this.loading.hideLoading();
        this.ngOnInit();
      }).catch((err) => {
        this.messageService.add({ severity: 'error', summary: 'Erro ao atualizar usuário!', detail: err });
        this.loading.hideLoading();
      });
      return;
    }
    this.addUserDiag = !this.addUserDiag;
    try {
      console.log(this.postForm.value);
      const routerState: any = this.router.routerState.snapshot.url;
      if (routerState == '/sistema/usuarios/venda') {
        this.postForm.get('tipo').setValue(4);
        this.postForm.get('idCidade').setValue(localStorage.getItem('idCidade') as string);
        this.postForm.get('endereco').setValue({
          cep: this.postForm.get('cep')?.value,
          rua: this.postForm.get('place')?.value,
          numero: this.postForm.get('num')?.value,
          bairro: this.postForm.get('neighborhood')?.value,
          complemento: this.postForm.get('complement')?.value,
          cidade: this.postForm.get('city')?.value,
          estado: this.postForm.get('state')?.value
        })
      };
      if (routerState == '/sistema/usuarios/fisc') {
        this.postForm.get('tipo').setValue(1);
      }
      if (routerState == '/sistema/usuarios/monitores') {
        this.postForm.get('tipo').setValue(2);
      }
      this.postForm.get('idCidade').setValue(localStorage.getItem('idCidade') as string);
      this.sessionService.newUser(routerState == '/sistema/usuarios/venda' ? 4 : this.postForm.value.tipo, this.postForm.value).then((res: any) => {
        this.messageService.add({ severity: 'success', summary: 'Usuário adicionado com sucesso', detail: '' });
        if (!hasAllField) {
          this.messageService.add({ severity: 'success', summary: 'Com a falta de informação do campo Rota, poderá haver impacto na Tela de Avaliação de Monitores', detail: '' });
        }
        this.userData.push({
          ...this.postForm.value,
          createdAt: new Date().toLocaleDateString('pt-BR'),
          status: 1
        });
        this.getUsers();
      }).catch((err: any) => {
        console.log("🚀 ~ file: usuarios.component.ts:213 ~ UsuariosComponent ~ this.sessionService.newUser ~ err:", err)
        this.messageService.add({ severity: 'error', summary: 'Erro ao adicionar usuário!', detail: err });
      });
    } catch (error: any) {
      console.log("🚀 ~ file: usuarios.component.ts:217 ~ UsuariosComponent ~ newUser ~ error:", error)
      this.messageService.add({ severity: 'error', summary: 'Erro ao adicionar usuário!', detail: error });
    }
  }

  updateUser(data?: any) {
    if (data.value) {
      console.log("🚀 ~ file: usuarios.component.ts:195 ~ UsuariosComponent ~ updateUser ~ data:", data)
      this.loading.showLoading();
      this.userService.putUser(data.value.id, { status: data.value.data }).then(() => {
        this.messageService.add({ severity: 'success', summary: 'Usuário atualizado com sucesso', detail: '' });
        this.loading.hideLoading();
      }).catch((err) => {
        this.messageService.add({ severity: 'error', summary: 'Erro ao atualizar usuário!', detail: err });
        this.loading.hideLoading();
      });
    }
    else {
      if (this.router.routerState.snapshot.url !== '/sistema/usuarios/clientes')
        this.edit = true;
      this.addUserDiag = !this.addUserDiag;
      (this.postForm as FormGroup).patchValue({
        ...data
      });
      this.updateValues = data;
    }
  }

  getUserFiltro() {
    console.log(this.userType);
  }

  newUserDiag() {
    this.addUserDiag = true;
    this.edit = false;
    this.postForm?.reset();
  }

}
