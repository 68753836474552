import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CidadeService } from 'src/app/service/cidade.service';
import { SessionService } from 'src/app/service/session.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';
import { Location } from "@angular/common";

export const ROUTES: any[] = [
  { path: '/sistema/operacoes', title: 'Operações' },
  { path: '/sistema/usuarios/sistema', title: 'Usuários do Sistema' },
  { path: '/sistema/usuarios/monitores', title: 'Monitores' },
  { path: '/sistema/usuarios/fisc', title: 'Agente de Fiscalização' },
  { path: '/sistema/usuarios/venda', title: 'Ponto de Vendas' },
  { path: '/sistema/usuarios/clientes', title: 'Clientes' },
  { path: '/sistema/cidades', title: 'Cidades' },
  { path: '/consultas/creditos', title: 'Créditos' },
  { path: '/consultas/infracoes', title: 'Infrações' },
  { path: '/fechamento/monitores', title: 'Fechamento Monitores' },
  { path: '/fechamento/pdv', title: 'Fechamento PDV' },
  { path: '/relatorio-financeiro', title: 'Relatório Financeiro' },
  { path: '/historicos/estacionamentos', title: 'Histórico de Estacionamentos' },
  { path: '/historicos/vendas', title: 'Histórico de Vendas' },
  { path: '/historicos/fiscalizacao', title: 'Histórico de Fiscalização' },
  { path: '/sistema/dashboard', title: 'Dashboard' },
  { path: '/sistema/mapa-em-tempo-real', title: 'Mapa em Tempo Real' },
  { path: '/sistema/veiculos-autorizados', title: 'Veículos Autorizados' },
  { path: '/consultas/avaliacao-monitores', title: 'Avaliação de Monitores' },
]

interface City {
  name: string;
  code: string;
}


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  cities: City[] | undefined;

  selectedCity: City | undefined;
  nameScreen: string = "";
  location: Location;

  userType: string = localStorage.getItem("tipoUsuario") as string;
  user = JSON.parse(sessionStorage.getItem('user') as string);

  status: boolean = false;
  tipouser: boolean = false;
  cityData: any[] = [];
  idCidade: string;
  dataCity: any[] = [];
  City: string;
  allCities: any;
  userTipo: any;
  isSvgClicked: boolean = false;

  toggleSvgColor() {
    this.isSvgClicked = !this.isSvgClicked;
  }

  activeItem: string = '';

  
  constructor(
    private readonly session_service: SessionService,
    private readonly cityService: CidadeService,
    private messageService: MessageService,
    private loading: LoadingService,
    private renderer: Renderer2,
    location: Location,
    private ref: ChangeDetectorRef,
    private router: Router
  ) { 
    this.location = location;
    this.getNameScreen();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getNameScreen();
      }
    });
  }

  getNameScreen() {
    var url = this.location.prepareExternalUrl(this.location.path());
    for (let i = 0; i < ROUTES.length; i++) {
      const e = ROUTES[i];
      if (url.indexOf(e.path) != -1) {
        this.nameScreen = ROUTES.filter(menuItem => menuItem)[i].title;
      }
    }
  }

  async ngOnInit(): Promise<void> {
    const usertipo = localStorage.getItem('tipoUsuario');
    this.userTipo = usertipo;
    this.idCidade = String(localStorage.getItem('idCidade'));
    this.getCidade();
    if (usertipo === '0') {
      this.tipouser = true;
    };

  }
  setActiveItem(item: string, event: Event) {
    this.activeItem = item;

    // Remova a cor branca de todos os ícones
    const allSvgElements = document.querySelectorAll('svg');
    allSvgElements.forEach((svg: any) => {
      this.renderer.setAttribute(svg.querySelector('path'), 'stroke', '#53ed5a');
    });

    // Defina a cor branca para o ícone clicado
    const svgElement = (event.currentTarget as HTMLElement).querySelector('svg');
    if (svgElement) {
      this.renderer.setAttribute(svgElement.querySelector('path'), 'stroke', '#fff');
    }
  }



  logout() {
    this.session_service.logout();
  }

  getCidade() {
    this.loading.showLoading();
    this.cityService.getCidades().then(async (result: any) => {
      this.cityData = await result.filter((f: any) => f.id !== this.idCidade);;
      this.dataCity = await result.filter((f: any) => f.id === this.idCidade);
      this.allCities = result;
      console.log("🚀 ~ file: sidebar.component.ts:50 ~ SidebarComponent ~ this.cityService.getCidades ~ this.allCities:", this.allCities)
      console.log('this.cityData ==>', this.dataCity);
      if (this.dataCity.length > 0) {
        this.City = this.dataCity[0].city;
      }
      this.loading.hideLoading();
    }).catch(async (erro: any) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao carregar cidades!', detail: erro });
      this.loading.hideLoading();
    });
  }

  setCidade(id: string) {
    this.idCidade = id;
    localStorage.setItem('idCidade', id);
    this.getCidade();
    location.reload();
  }

}
