import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FechamentoPdvService {

  base_url = `${environment.baseUrl}`;
  constructor(private http: HttpClient) { }

  getDropdownFechamentoPdv(idCidade: string) {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.base_url}/v1/admin/dropdown-fechamento-pdv?cidade=${idCidade}`, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }
  
  getCalculoPeriodoPdv(dateBegin?: string, dateEnd?: string, idPos?: string): Observable<any>{
    const accessToken = localStorage.getItem('token');
    const idCidade = localStorage.getItem('idCidade');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return this.http.get<any>(`${this.base_url}/v1/admin/fechamento-pdv?cidade=${idCidade}&final=${dateEnd}&inicio=${dateBegin}&idUsuarioPDV=${idPos}`, httpOptions).pipe(take(1));
  }

}
