<div class="min-h-screen flex flex-column overflow-hidden" [style]="{'background' : '#F1F1F1'}">
  <div class="bg-background py-3 px-6 flex align-items-center justify-content-between lg:absolute left-0 top-0 h-fit pointer-events-none" style="overflow: visible; min-height: 170px;">
    <a pRipple class="cursor-pointer inline-flex align-items-center justify-content-center lg:hidden text-700"
      pStyleClass="#app-sidebar-12" enterClass="hidden" enterActiveClass="fadeinleft" leaveToClass="hidden"
      leaveActiveClass="fadeoutleft" [hideOnOutsideClick]="true">
      <i class="pi pi-bars text-4xl"></i>
    </a>
    <a class="navbar-brand">{{ this.nameScreen }}</a>
    <div [pTooltip]="'Bem-vindo! Você está logado como ' + user?.username" tooltipPosition="bottom"
      class="flex flex-wrap user mt-4" style="pointer-events: all;">
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="35" class="bi bi-person-circle cursor-pointer"
        viewBox="0 0 16 16" fill="#53EE5A">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
        <path fill-rule="evenodd"
          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
      </svg>

      <div class="flex flex-column align-items-center">
        <span class="nameUser">{{user?.nome}}</span>
        <h6 class="codUser">{{user?.username}}</h6>
      </div>
    </div>
    <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterClass="hidden"
      leaveToClass="hidden" [hideOnOutsideClick]="true">
      <i class="pi pi-ellipsis-v text-2xl"></i>
    </a>

  </div>

  <div class="flex p-5 pt-0 flex-auto overflow-hidden content" style="margin-top: 2%;">
    <div id="app-sidebar-12"
      class="bg-sb hidden lg:block flex-shrink-0 absolute lg:sticky left-0 top-0 z-2 select-none shadow-2"
      style="width:260px;border-radius: 12px">
      <div class="flex flex-column h-full">
        <div class="overflow-y-auto">
          <div class="px-3 inline-flex flex-column" routerLink="/sistema/dashboard">
            <img class="cursor-pointer" src="assets/images/icons/logo_menu.svg" alt="logo-smart"
              [style]="{'width': '100%', 'height': '9rem'    }">
            <span class="version py-2">Versão 2.0.10</span>
          </div>
          <ul class="list-none px-3 pt-0 m-0">
            <li>
              <div class="pb-3">
                <div class="list-city w-full">
            <li>
              <a pRipple class="flex align-items-center cursor-pointer py-3  border-round text-bluegray-100 hover:text-bluegray-50
                                          transition-duration-150 transition-colors" pStyleClass="@next"
                enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup">
                <span class=" titleSidebar">{{City}}</span>
                <i class="pi pi-chevron-down ml-auto" *ngIf="tipouser"></i>
              </a>
              <ul
                class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                *ngIf="tipouser">
                <li (click)="setCidade(item.id)" *ngFor="let item of allCities">
                  <a pRipple class="flex align-items-center cursor-pointer px-3  border-round text-blue-600 hover:text-bluegray-50
                                              transition-duration-150 transition-colors">
                    <span class=" subtitleSidebar label-telas">{{item.city}}</span>
                  </a>
                </li>
              </ul>
            </li>
        </div>
      </div>

      <ul class="list-none p-0 m-0 overflow-hidden">
        <!-- Master + Operacional -->
        <li *ngIf="userTipo == '0' || userTipo == '7'" routerLinkActive="active" routerLink="dashboard">
          <a pRipple class="label-telas flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors titleSidebar label-telas
                             ">
            <i class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="19.438" height="19.438" viewBox="0 0 19.438 19.438">
                <g id="ic_menu_dashboard" transform="translate(-88.605 -357.959)">
                  <path id="Caminho_968" data-name="Caminho 968"
                    d="M14.2,8H9.06A1.06,1.06,0,0,0,8,9.06V15.4a1.06,1.06,0,0,0,1.06,1.06H14.2a1.06,1.06,0,0,0,1.06-1.06V9.06A1.06,1.06,0,0,0,14.2,8Z"
                    transform="translate(81.355 350.709)" fill="none" stroke="#53ed5a" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" />
                  <path id="Caminho_969" data-name="Caminho 969"
                    d="M41.594,8H36.455A1.06,1.06,0,0,0,35.4,9.06v1.509a1.06,1.06,0,0,0,1.06,1.06h5.139a1.06,1.06,0,0,0,1.06-1.06V9.06A1.06,1.06,0,0,0,41.594,8Z"
                    transform="translate(64.639 350.709)" fill="none" stroke="#53ed5a" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" />
                  <path id="Caminho_970" data-name="Caminho 970"
                    d="M41.594,25.121H36.455a1.06,1.06,0,0,0-1.06,1.06V34.95a1.06,1.06,0,0,0,1.06,1.06h5.139a1.06,1.06,0,0,0,1.06-1.06V26.181A1.06,1.06,0,0,0,41.594,25.121Z"
                    transform="translate(64.639 340.637)" fill="none" stroke="#53ed5a" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" />
                  <path id="Caminho_971" data-name="Caminho 971"
                    d="M14.2,38.818H9.06A1.06,1.06,0,0,0,8,39.878v3.929a1.06,1.06,0,0,0,1.06,1.06H14.2a1.06,1.06,0,0,0,1.06-1.06V39.878A1.06,1.06,0,0,0,14.2,38.818Z"
                    transform="translate(81.355 331.78)" fill="none" stroke="#53ed5a" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" />
                </g>
              </svg>

            </i>
            <span class="font-medium titleSidebar label-telas ml-2" routerLinkActive="active">Dashboard</span>

          </a>
        </li>

        <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '7'" routerLinkActive="active" routerLink="mapa-em-tempo-real">
          <a pRipple class="label-telas flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors titleSidebar label-telas
                             ">
            <i class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="17.812" viewBox="0 0 18.5 17.812">
                <path id="ic_menu_mapa"
                  d="M11.5,5.833h.009M8.667,19.056,3,16.222V3.944l1.889.944M8.667,19.056l5.667-2.833M8.667,19.056V13.389m5.667,2.833L20,19.056V6.778l-1.889-.944M14.333,16.222V13.389m0-7.367c0,1.669-1.417,3.022-2.833,4.533C10.083,9.044,8.667,7.691,8.667,6.022A2.932,2.932,0,0,1,11.5,3,2.932,2.932,0,0,1,14.333,6.022Z"
                  transform="translate(-2.25 -2.25)" fill="none" stroke="#53ed5a" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1.5" />
              </svg>

            </i>
            <span class="font-medium titleSidebar label-telas ml-2" routerLinkActive="active">Mapa em Tempo Real</span>

          </a>
        </li>

        <!-- Master -->
        <li *ngIf="userTipo == '0'" routerLinkActive="active" routerLink="operacoes"
          (click)="setActiveItem('operacoes', $event)">
          <a pRipple
            class="label-telas flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors titleSidebar label-telas">
            <i class="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
                <g id="city-svgrepo-com" transform="translate(-2.25 -2.25)">
                  <path id="Caminho_972" data-name="Caminho 972" d="M7,9.01,7.01,9" fill="none" stroke="#53ed5a"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <path id="Caminho_973" data-name="Caminho 973" d="M11,9.01,11.01,9" fill="none" stroke="#53ed5a"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <path id="Caminho_974" data-name="Caminho 974" d="M7,13.01,7.01,13" fill="none" stroke="#53ed5a"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <path id="Caminho_975" data-name="Caminho 975" d="M11,13.01,11.01,13" fill="none" stroke="#53ed5a"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <path id="Caminho_976" data-name="Caminho 976" d="M7,17.01,7.01,17" fill="none" stroke="#53ed5a"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <path id="Caminho_977" data-name="Caminho 977" d="M11,17.01,11.01,17" fill="none" stroke="#53ed5a"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <path id="Caminho_978" data-name="Caminho 978"
                    d="M15,21H3.6a.6.6,0,0,1-.6-.6V5.6A.6.6,0,0,1,3.6,5H9V3.6A.6.6,0,0,1,9.6,3h4.8a.6.6,0,0,1,.6.6V9m0,12h5.4a.6.6,0,0,0,.6-.6V9.6a.6.6,0,0,0-.6-.6H15m0,12V17m0-8v4m0,0h2m-2,0v4m0,0h2"
                    fill="none" stroke="#53ed5a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                </g>
              </svg>
            </i>
            <span class="font-medium titleSidebar label-telas ml-2" routerLinkActive="active">Operações</span>
          </a>
          <!-- Master + Local -->
        </li>
        <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="veiculos-autorizados">
          <a pRipple
            class="flex align-items-center cursor-pointer py-3 text-700 transition-duration-150 transition-colors border-right-2 border-transparent">
            <i class="mr-2 ">
              <img src="/assets/images/icons/ic_menu_veiculos.svg" alt="icon-veiculos-autorizados">
            </i>
            <span class="font-medium label-telas titleSidebar ml-2" routerLinkActive="active">Veículos
              Autorizados</span>
          </a>
        </li>
        <li *ngIf="userTipo == '0' || userTipo == '5'">
          <a pRipple
            class="flex align-items-center cursor-pointer py-3 text-700 transition-duration-150 transition-colors border-right-2 border-transparent"
            pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
            leaveActiveClass="slideup">
            <i class="mr-2">
              <img src="/assets/images/icons/ic_menu_usuarios.svg" alt="icon-usuarios">
            </i>
            <span class="font-medium titleSidebar ml-3">Usuários</span>
            <i class="pi pi-chevron-down ml-auto"></i>
          </a>

          <ul [style]="{'padding-left': '2.5rem'}"
            class="list-none py-0 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
            <li *ngIf="userTipo == '0'" routerLinkActive="active" routerLink="usuarios/sistema">
              <a pRipple
                class="py-2 flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="font-medium label-telas" routerLinkActive="active">Usuários do Sistema</span>
              </a>
            </li>
            <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="usuarios/monitores">
              <a pRipple
                class="py-2 flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="label-telas" routerLinkActive="active">Monitores</span>
              </a>
            </li>
            <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active"
              routerLink="usuarios/fisc">
              <a pRipple
                class="py-2 flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="label-telas" routerLinkActive="active">Agentes de Fiscalização</span>
              </a>
            </li>
            <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="usuarios/venda">
              <a pRipple
                class="py-2 flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="label-telas" routerLinkActive="active">Ponto de Vendas</span>
              </a>
            </li>
            <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="usuarios/clientes">
              <a pRipple
                class="py-2 flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="label-telas" routerLinkActive="active">Clientes</span>
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a pRipple
            class="flex align-items-center cursor-pointer py-3 text-700 transition-duration-150 transition-colors border-right-2 border-transparent"
            pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
            leaveActiveClass="slideup">
            <i class="mr-2">
              <img src="/assets/images/icons/ic_menu_historicos.svg" alt="icon-historicos">
            </i>
            <span class="font-medium titleSidebar ml-3">Históricos</span>
            <i class="pi pi-chevron-down ml-auto"></i>
          </a>
          <ul [style]="{'padding-left': '2.5rem'}"
            class="list-none py-0 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
            <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '7'" routerLinkActive="active" routerLink="historicos/vendas">
              <a pRipple
                class="flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="font-medium label-telas" routerLinkActive="active">Vendas</span>
              </a>
            </li>
            <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '7'" routerLinkActive="active"
              routerLink="historicos/estacionamentos">
              <a pRipple
                class="flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="font-medium label-telas" routerLinkActive="active">Estacionamentos</span>
              </a>
            </li>
            <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '6' || userTipo == '7'" routerLinkActive="active"
              routerLink="historicos/fiscalizacao">
              <a pRipple
                class="flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="font-medium label-telas" routerLinkActive="active">Fiscalização</span>
              </a>
            </li>

          </ul>
        </li>

        <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '7'">
          <a pRipple
            class="flex align-items-center cursor-pointer py-3 text-700 transition-duration-150 transition-colors border-right-2 border-transparent"
            pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
            leaveActiveClass="slideup">
            <i class="mr-2">
              <img src="/assets/images/icons/ic_menu_consulta.svg" alt="icon-consultas">
            </i>
            <span class="font-medium titleSidebar ml-3">Consultas</span>
            <i class="pi pi-chevron-down ml-auto"></i>
          </a>
          <ul [style]="{'padding-left': '2.5rem'}"
            class="list-none py-0 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
            <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active" routerLink="consultas/creditos">
              <a pRipple
                class="flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="font-medium label-telas" routerLinkActive="active">Créditos</span>
              </a>
            </li>
            <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active"
              routerLink="consultas/infracoes/1/10">
              <a pRipple
                class="flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="font-medium label-telas" routerLinkActive="active">Infrações</span>
              </a>
            </li>
            <li *ngIf="userTipo == '0' || userTipo == '5'" routerLinkActive="active"
              routerLink="consultas/avaliacao-monitores">
              <a pRipple
                class="flex align-items-center cursor-pointer py-3 transition-duration-150 transition-colors border-right-2 border-transparent">
                <span class="font-medium label-telas" routerLinkActive="active">Avaliação de Monitores</span>
              </a>
            </li>

          </ul>
        </li>

        <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '7'" routerLinkActive="active" routerLink="fechamento/monitores">
          <a pRipple
            class="flex align-items-center cursor-pointer py-3 text-700 transition-duration-150 transition-colors border-right-2 border-transparent">
            <i class="mr-2">
              <img src="/assets/images/icons/ic_menu_historicos.svg" alt="icon-fechamento-monitores">
            </i>
            <span class="font-medium label-telas titleSidebar ml-2" routerLinkActive="active">Fechamento
              Monitores</span>
          </a>
        </li>

        <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '7'" routerLinkActive="active" routerLink="fechamento/pdv">
          <a pRipple
            class="flex align-items-center cursor-pointer py-3 text-700 transition-duration-150 transition-colors border-right-2 border-transparent">
            <i class="mr-2">
              <img src="/assets/images/icons/ic_menu_pdv.svg" alt="icon-pdv">
            </i>
            <span class="font-medium label-telas titleSidebar ml-2" routerLinkActive="active">Fechamento PDV</span>
          </a>
        </li>

        <li *ngIf="userTipo == '0' || userTipo == '5' || userTipo == '6'" routerLinkActive="active"
          routerLink="relatorio-financeiro">
          <a pRipple
            class="flex align-items-center cursor-pointer py-3 text-700 transition-duration-150 transition-colors border-right-2 border-transparent">
            <i class="mr-2">
              <img src="/assets/images/icons/ic_menu_financeiro.svg" alt="icon-relatorio-financeiro">
            </i>
            <span class="font-medium label-telas titleSidebar ml-2" routerLinkActive="active">Relatório
              Financeiro</span>
          </a>
        </li>

        <li (click)="logout()">
          <a pRipple
            class="flex align-items-center cursor-pointer py-3 text-700 transition-duration-150 transition-colors border-right-2 border-transparent">
            <i class="mr-2">
              <img src="/assets/images/icons/ic_menu_sair.svg" alt="icon-sair">
            </i>
            <span class="font-medium label-telas titleSidebar ml-2" routerLinkActive="active">Sair</span>
          </a>
        </li>
      </ul>
      </li>
      </ul>

    </div>

  </div>
    </div>
    <div class="flex flex-column flex-auto pt-6">
      <div class=" border-round flex-auto lg:ml-5">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>