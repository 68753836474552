<section class="position-relative pt-5">
  <div class="filter-div py-3 pb-0 mt-3 d-flex mb-2">
    <div class="me-3 xxl:inline-flex align-items-center">
      <b class="me-3 titleCalendar">De</b>
      <input type="date" [(ngModel)]="dataInicio" class="mt-2">
    </div>
    <div class="me-3 xxl:inline-flex align-items-center">
      <b class="me-3 titleCalendar">Até</b>
      <input type="date" [(ngModel)]="dataFim" class="mt-2">
    </div>

    <div class="me-3 block xxl:inline-flex align-items-center">
      <b class="me-2 titleFilter" style="white-space: nowrap;">Canal de Vendas</b>
      <p-dropdown [options]="vendas" [(ngModel)]="selectedVendas" optionLabel="name" optionValue="value"></p-dropdown>

    </div>

    <div class="me-3 flex align-items-end">
      <button (click)="ngOnInit()" pButton pRipple label="Filtrar" icon="pi pi-filter"
        class="block font-bold white-space-nowrap p-button-outlined p-button-filter"></button>

    </div>
    <!-- Exportar -->
    <p-button (onClick)="export()" label="Exportar (.csv)" class="flex align-items-end add-button ms-auto"></p-button>
  </div>



  <div class="table-div">
    <div class="grid">
      <div class="col-12 md:col-6 lg:col-6">
        <div class="cardVendas p-3 ">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-title mb-3">Canal de Vendas</span>
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-4">
              <span class="text-title">Geral: </span>
              <span class="text-footer"> {{canalDeVendas?.geral | toReal}}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
              <span class="text-title">App: </span>
              <span class="text-footer"> {{canalDeVendas?.app | toReal}}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
              <span class="text-title">PDV: </span>
              <span class="text-footer"> {{canalDeVendas?.pdv | toReal}}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
              <span class="text-title">Monitores: </span>
              <span class="text-footer"> {{canalDeVendas?.monitores | toReal}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-6">
        <div class="cardVendas p-3 ">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-title mb-3">Meios de Pagamento</span>
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-4">
              <span class="text-title">Crédito: </span>
              <span class="text-footer">{{meiosDePagamento?.credito | toReal}}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
              <span class="text-title">Débito: </span>
              <span class="text-footer">{{meiosDePagamento?.debito | toReal}}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
              <span class="text-title">Pix: </span>
              <span class="text-footer">{{meiosDePagamento?.pix | toReal}}</span>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
              <span class="text-title">Dinheiro: </span>
              <span class="text-footer">{{meiosDePagamento?.dinheiro | toReal}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-generic-table *ngIf="dataVendas.length > 0; else empty"
      [item]="{colunas: colunas, data: dataVendas, type: 1, config: {disableSort: true, fields: fields, paginator: false}}"
      class="p-5 pb-0"></app-generic-table>
    <p-paginator (onPageChange)="changePage($event)" [first]="0" [rows]="perPage" [totalRecords]="totalItems"
     ></p-paginator>
  </div>
</section>

<ng-template #empty>
  <div class="empty-table">
    <p class="text-center">Nenhum registro encontrado</p>
  </div>
</ng-template>
