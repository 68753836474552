import { OperacoesComponent } from './pages/operacoes/operacoes.component';
import { RelatorioFinanceiroComponent } from './pages/relatorio-financeiro/relatorio-financeiro.component';
import { FechamentoComponent } from './pages/fechamento/fechamento.component';
import { FiscalizacaoComponent } from './pages/fiscalizacao/fiscalizacao.component';
import { VendasComponent } from './pages/vendas/vendas.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CidadesComponent } from './pages/cidades/cidades.component';
import { ConsultaDeCreditosComponent } from './pages/consulta-de-creditos/consulta-de-creditos.component';
import { ConsultaDePlacasComponent } from './pages/consulta-de-placas/consulta-de-placas.component';
import { ConsultaDoDiaDeTrabalhoComponent } from './pages/consulta-do-dia-de-trabalho/consulta-do-dia-de-trabalho.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EstacionamentosComponent } from './pages/estacionamentos/estacionamentos.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { AuthGuardService } from './service/authguard.service';
import { ConsultaDeMultasComponent } from './pages/consulta-de-multas/consulta-de-multas.component';
import { VeiculosPrefeituraComponent } from './pages/veiculos-prefeitura/veiculos-prefeitura.component';
import { AvaliacaoMonitoresComponent } from './pages/avaliacao-monitores/avaliacao-monitores.component';
import { ReciboComponent } from './pages/recibo/recibo.component';
import { MapaTempoRealComponent } from './pages/mapa-tempo-real/mapa-tempo-real.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'recibo/:id', component: ReciboComponent },
  {
    path: 'sistema', component: SidebarComponent,
    children: [
      {
        path: 'usuarios', children: [
          { path: 'sistema', component: UsuariosComponent, canActivate: [AuthGuardService] },
          { path: 'monitores', component: UsuariosComponent, canActivate: [AuthGuardService] },
          { path: 'fisc', component: UsuariosComponent, canActivate: [AuthGuardService] },
          { path: 'venda', component: UsuariosComponent, canActivate: [AuthGuardService] },
          { path: 'clientes', component: UsuariosComponent, canActivate: [AuthGuardService] },
        ]
      },
      {
        path: 'veiculos-autorizados', component: VeiculosPrefeituraComponent, canActivate: [AuthGuardService]
      },
      {
        path: 'operacoes', component: OperacoesComponent, canActivate: [AuthGuardService]
      },
      {
        path: 'historicos', children: [
          { path: 'estacionamentos', component: EstacionamentosComponent, canActivate: [AuthGuardService] },
          { path: 'vendas', component: VendasComponent, canActivate: [AuthGuardService] },
          { path: 'fiscalizacao', component: FiscalizacaoComponent, canActivate: [AuthGuardService] }

        ]
      },
      {
        path: 'consultas', children: [
          { path: 'creditos', component: ConsultaDeCreditosComponent, canActivate: [AuthGuardService] },
          { path: 'creditos/:page/:perPage', component: ConsultaDeCreditosComponent, canActivate: [AuthGuardService] },
          { path: 'infracoes/:page/:perPage', component: ConsultaDeMultasComponent, canActivate: [AuthGuardService] },
          { path: 'avaliacao-monitores', component: AvaliacaoMonitoresComponent, canActivate: [AuthGuardService] }
        ]
      },
      {
        path: 'fechamento', children: [
          { path: 'monitores', component: FechamentoComponent, canActivate: [AuthGuardService] },
          { path: 'pdv', component: FechamentoComponent, canActivate: [AuthGuardService] }
        ]
      },
      {
        path: 'relatorio-financeiro', component: RelatorioFinanceiroComponent, canActivate: [AuthGuardService]
      },
      { path: 'cidades', component: CidadesComponent, canActivate: [AuthGuardService] },
      { path: 'consulta-de-placas', component: ConsultaDePlacasComponent, canActivate: [AuthGuardService] },
      { path: 'consulta-do-dia-de-trabalho', component: ConsultaDoDiaDeTrabalhoComponent, canActivate: [AuthGuardService] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
      {path: 'mapa-em-tempo-real', component: MapaTempoRealComponent, canActivate: [AuthGuardService]}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
