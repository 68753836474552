<div class="border mt-4">
    <div class="col-4 relative z-2 top-0 right-0">
        <p-card [style]="{'width': '85%'}">
            <div class="header-card flex justify-content-between">
                <h6>Filtros</h6>
                <small>Quantidade de Agentes: {{agentes.length || 0}}</small>
            </div>
            <!-- @for ( category of categories; track category.id) {
            <div class="field-checkbox">
                <p-checkbox [(ngModel)]="selectedCategories" [label]="category.name" name="group" [value]="category" />
            </div>
            } -->

            <div>
                <p>Pesquisar por usuário</p>
                <p-iconField iconPosition="left">
                    <p-inputIcon styleClass="pi pi-search" />
                    <input type="text" class="w-full" [(ngModel)]="filter" pInputText placeholder="Digite" />
                </p-iconField>

                <div class="grid justify-content-center  gap-2 mt-4">
                    <p-button label="Limpar Filtros" (onClick)="filter = ''; filterAgenteByNome()" [rounded]="true" severity="success" />
                    <p-button label="Filtrar" (onClick)="filterAgenteByNome()" [rounded]="true" styleClass="p-button-filter" />

                </div>
            </div>

        </p-card>

    </div>
    <google-map #map [options]="options" class="absolute top-0 left-0 w-full h-full z-1">
        @for (marker of agentes; track $index) {
        <map-marker #markerElem="mapMarker" (mapClick)="openInfo(markerElem, $index)" [position]="marker.position" [title]="marker.title"
            [options]="markerOptions">
        </map-marker>
        <map-info-window #infoWindow="mapInfoWindow"  [options]="infoWindowOptions">
            <div class="flex gap-2">
                <b class="font-bold">Ident.:</b>
                <span>{{marker.title}}</span>
            </div>
            <div class="flex gap-2">
                <b class="font-bold">Endereço:</b>
                <span>{{marker.address}}</span>
            </div>
        </map-info-window>
        }
    </google-map>
</div>