import { Component } from '@angular/core';
import { ExcelService } from 'src/app/service/excel.service';
import { RelatorioFinanceiroService } from 'src/app/service/relatorio-financeiro.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-relatorio-financeiro',
  templateUrl: './relatorio-financeiro.component.html',
  styleUrls: ['./relatorio-financeiro.component.scss']
})
export class RelatorioFinanceiroComponent {

  dataInicio = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  dataFim = new Date().toISOString().replace(/ /g, "-").split("T")[0];

  constructor(private Relatorioservice: RelatorioFinanceiroService, private excel: ExcelService, private loading: LoadingService) { }

  relatorioData: any;
  meiosPagamento: any;
  totalVendas: any;

  ngOnInit(): void {
    this.getRelatorioFinaceiro();
  }

  getPercent(value: any) {
    console.log("🚀 ~ file: relatorio-financeiro.component.ts:27 ~ RelatorioFinanceiroComponent ~ getPercent ~ this.totalVendas:", this.totalVendas)
    return ((Number(value) * 100) / Number(this.totalVendas?.geral)).toString().replace(".", ",").slice(0, 4);
  }

  getRelatorioFinaceiro() {
    this.loading.showLoading();
    this.Relatorioservice.getRelatorioFinanceiro(new Date(this.dataInicio).toISOString(), new Date(this.dataFim).toISOString()).subscribe({
      next: (result) => {
        this.relatorioData = result.DATA;
        this.meiosPagamento = result.DATA.meiosPagamento;
        this.totalVendas = result.DATA.totalVendas;
      },
      error: (error) => {
        console.log(error);
        this.loading.hideLoading();

      },
      complete: () => {
        console.log("ok");
        this.loading.hideLoading();
      }
    })
  }

  export() {
    // this.excel.exportAsExcelFile(this.getDatasToExcel(), 'relatórios');
    // window.print();
    const data = document.getElementById('contentToConvert') as any;
    html2canvas(data).then((canvas: any) => {
      // Poucas configurações para o PDF
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
  
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save(`Relatório Financeiro - ${new Date(this.dataInicio).toLocaleDateString('pt-BR')}-${new Date(this.dataFim).toLocaleDateString('pt-BR')}.pdf`); // Gera o PDF
    });
  }

  getDatasToExcel(): any[] {
    const newArray1: any[] = [];
    const newArray2: any[] = [];
    const newArray3: any[] = [];
    const adminArray: any[] = [];
    const finalArray: any[] = [];
    
    const totalVendasOrder = ['monitores', 'app', 'pdv', 'geral'];
    const meiosPagamentoOrder = ['debito', 'credito', 'dinheiro', 'pix'];

    const totalVendasSum = Object.values(this.totalVendas).reduce((acc: any, val) => acc + val, 0) as any;
    const meiosPagamentoSum = Object.values(this.meiosPagamento).reduce((acc: any, val) => acc + val, 0) as any;

    totalVendasOrder.forEach(key => {
        const val = this.totalVendas[key];
        newArray1.push({
            "Total Vendas - Campo": key,
            "Total Vendas - Valor": val,
            "Total Vendas - Percentual": totalVendasSum ? (val / totalVendasSum * 100).toFixed(2) + '%' : '0%',
        });
    });

    Object.entries(this.relatorioData).forEach(([key, val]) => {
        if (key !== 'meiosPagamento' && key !== 'totalVendas') {
            newArray2.push({
                "Renumeração Outorga - Campo": key,
                "Renumeração Outorga - Valor": val === null ? 0 : val,
            });
        }
    });

    meiosPagamentoOrder.forEach(key => {
        const val = this.meiosPagamento[key];
        newArray3.push({
            "Meios de Pagamento - Campo": key,
            "Meios de Pagamento - Valor": val,
            "Meios de Pagamento - Percentual": meiosPagamentoSum ? (val / meiosPagamentoSum * 100).toFixed(2) + '%' : '0%',
        });
    });

    adminArray.push({
        "Administração - Valor Bruto": this.relatorioData?.saldo,
        "Percentual da Administração": this.relatorioData?.remuneracaoOutorga,
        "Saldo Líquido": this.relatorioData?.valor
    });

    const length = Math.max(...[newArray1.length, newArray2.length, newArray3.length, adminArray.length]);
    for (let index = 0; index < length; index++) {
        const element1 = newArray1[index] || {};
        const element2 = newArray2[index] || {};
        const element3 = newArray3[index] || {};
        const elementAdmin = adminArray[index] || {};
        finalArray.push(Object.assign(element1, element2, element3, elementAdmin));
    }
    return finalArray;
}

  
}
