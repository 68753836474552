import { Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { GoogleMap, GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { RealtimeLocationService } from 'src/app/service/realtime-location.service';
import { OperacoesService } from 'src/app/service/operacoes.service';


@Component({
  selector: 'app-mapa-tempo-real',
  standalone: true,
  imports: [
    FormsModule,
    CardModule,
    CheckboxModule,
    InputIconModule,
    IconFieldModule,
    InputTextModule,
    ButtonModule,
    GoogleMapsModule
  ],
  templateUrl: './mapa-tempo-real.component.html',
  styleUrl: './mapa-tempo-real.component.css',
  encapsulation: ViewEncapsulation.None
})
export class MapaTempoRealComponent implements OnInit {

  @ViewChild('map') gmap: GoogleMap;
  @ViewChildren(MapInfoWindow) infoWindows!: QueryList<MapInfoWindow>;

  constructor(private readonly realtimeService: RealtimeLocationService, private readonly operationService: OperacoesService) { }

  public agentes: any[] = [];

  public firstTime: boolean = false;

  public filter: string = '';
  
  public agentesBackup: any[] = [];

  async openInfo(marker: any, index: any, data: any) {
    const endereco = (await this.operationService.getAddress(Number(marker._position.lat), Number(marker._position.lng)));
    data.address = endereco;
    const infoWindow = this.infoWindows.toArray()[Number(index)];
    if (infoWindow) {
      infoWindow.open(marker);
    }
  }

  ngOnInit(): void {
    this.realtimeService.getRealtimeLocation().subscribe((data) => {
      if (this.firstTime) {
        setTimeout(() => {
          data.forEach((novoAgente: any) => {
            const agenteExistente = this.agentes.find((agente) => agente.id === novoAgente.id);
            if (agenteExistente) {
              agenteExistente.position = novoAgente.position;
            }
          });
          // console.log("🚀 ~ MapaTempoRealComponent ~ data.forEach ~ this.agentes:", this.agentes);
          if(this.agentes.length == 0){
            this.firstTime = false;
          }
        }, 1000);
      } else {
        this.waitForDataAndPanTo(data);
      }
      if (this.filter) {
        this.agentes = this.agentesBackup.filter((agente) => agente.title.includes(this.filter));
      }
      if(!data || data.length == 0) {
        this.agentes = [];
        return;
      }
    });
  }
  

  private waitForDataAndPanTo(data: any): void {
    const interval = setInterval(() => {
      // Verifica se os dados e a posição estão disponíveis
      if (data && data.length > 0 && data[0].position && !this.firstTime) {
        this.firstTime = true;
        this.agentes = data;
        this.agentesBackup = [...data];
        console.log("🚀 ~ MapaTempoRealComponent ~ Dados disponíveis:", this.agentes[0]);
        
        // Centraliza o mapa na posição do primeiro agente
        this.gmap.panTo(this.agentes[0].position);
        
        // Limpa o intervalo após ter encontrado os dados
        clearInterval(interval);
      }
    }, 1000); // Verifica a cada 1 segundo
  }

  filterAgenteByNome(): void {
    if(this.filter) {
      this.agentes = this.agentesBackup.filter((agente) => agente.title.toLowerCase().includes(this.filter.toLowerCase()));
    } else {
      this.agentes = this.agentesBackup;
    }
    console.log("🚀 ~ MapaTempoRealComponent ~ filterAgenteByNome ~ this.agentes:", this.agentes)
    
  }

  selectedCategories: any[] = [];

  categories: any[] = [
    { name: 'Agente', key: 'A' },
    { name: 'Monitor', key: 'M' },
    { name: 'PDV', key: 'P' }
  ];

  public options: google.maps.MapOptions = {
    center: { lat: -19.982139763265863, lng: -43.85497036478432 },
    zoom: 12,
    disableDefaultUI: true,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ]
  }

  public markerOptions: google.maps.MarkerOptions = {
    animation: google.maps.Animation.DROP,
    icon: '/assets/images/icons/pin_agente.png'
  }

  public readonly infoWindowOptions: google.maps.InfoWindowOptions = {
    
  }

}
