import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

//NGPrime Modules
import { AccordionModule } from 'primeng/accordion';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { LoginComponent } from './components/login/login.component';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { PasswordModule } from 'primeng/password';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BadgeModule } from 'primeng/badge';
import { RippleModule } from 'primeng/ripple';
import { InputMaskModule } from 'primeng/inputmask';
import { PaginatorModule } from 'primeng/paginator';


import { SidebarComponent } from './components/sidebar/sidebar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { GenericTableComponent } from './components/generic-table/generic-table.component';
import { ExcelService } from './service/excel.service';
import { MessageService } from 'primeng/api';
import { CidadesComponent } from './pages/cidades/cidades.component';
import { ConsultaDeCreditosComponent } from './pages/consulta-de-creditos/consulta-de-creditos.component';
import { EstacionamentosComponent } from './pages/estacionamentos/estacionamentos.component';
import { ConsultaDePlacasComponent } from './pages/consulta-de-placas/consulta-de-placas.component';
import { ConsultaDoDiaDeTrabalhoComponent } from './pages/consulta-do-dia-de-trabalho/consulta-do-dia-de-trabalho.component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MultiSelectModule } from 'primeng/multiselect';
import { SlideMenuModule } from 'primeng/slidemenu';
import { Interceptor } from './app.interceptor.module';
import { StyleClassModule } from 'primeng/styleclass';
import { ConsolePipe } from './pipes/console.pipe';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { VendasComponent } from './pages/vendas/vendas.component';
import { FiscalizacaoComponent } from './pages/fiscalizacao/fiscalizacao.component';
import { FechamentoComponent } from './pages/fechamento/fechamento.component';
import { RelatorioFinanceiroComponent } from './pages/relatorio-financeiro/relatorio-financeiro.component';
import { OperacoesComponent } from './pages/operacoes/operacoes.component';
import { FiltroPipe } from './pipes/filtro.pipe';
import { ToRealPipe } from './pipes/to-real.pipe';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ConsultaDeMultasComponent } from './pages/consulta-de-multas/consulta-de-multas.component';
import { VeiculosOficiaisComponent } from './pages/veiculos-oficiais/veiculos-oficiais.component';
import { ImageModule } from 'primeng/image';
import { VeiculosPrefeituraComponent } from './pages/veiculos-prefeitura/veiculos-prefeitura.component';
import { AvaliacaoMonitoresComponent } from './pages/avaliacao-monitores/avaliacao-monitores.component';
import { ReciboComponent } from './pages/recibo/recibo.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { ChartModule } from 'primeng/chart';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { GoogleMapsModule } from '@angular/google-maps'

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        UsuariosComponent,
        SidebarComponent,
        GenericTableComponent,
        CidadesComponent,
        ConsultaDeCreditosComponent,
        EstacionamentosComponent,
        ConsultaDePlacasComponent,
        ConsultaDoDiaDeTrabalhoComponent,
        ConsolePipe,
        DashboardComponent,
        VendasComponent,
        FiscalizacaoComponent,
        FechamentoComponent,
        RelatorioFinanceiroComponent,
        OperacoesComponent,
        FiltroPipe,
        ToRealPipe,
        ConsultaDeMultasComponent,
        VeiculosOficiaisComponent,
        VeiculosPrefeituraComponent,
        AvaliacaoMonitoresComponent,
        ReciboComponent,
    ],
    bootstrap: [AppComponent, HttpClient],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ButtonModule,
        CommonModule,
        AppRoutingModule,
        ChartModule,
        AccordionModule,
        MenuModule,
        InputTextModule,
        InputNumberModule,
        PasswordModule,
        ReactiveFormsModule,
        FormsModule,
        TableModule,
        DropdownModule,
        DialogModule,
        CalendarModule,
        ToastModule,
        ProgressSpinnerModule,
        MultiSelectModule,
        SlideMenuModule,
        Interceptor,
        BadgeModule,
        RippleModule,
        StyleClassModule,
        InputMaskModule,
        PaginatorModule,
        ImageModule,
        InputSwitchModule,
        TooltipModule,
        GoogleMapsModule
    ],
    providers: [FormsModule, ExcelService, MessageService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, provideHttpClient(withInterceptorsFromDi()), provideFirebaseApp(() => initializeApp({
        "projectId": "rotativo-smart-parking",
        "appId": "1:110514863393:web:26793aae26b1cb05009260",
        "databaseURL": "https://rotativo-smart-parking-default-rtdb.firebaseio.com",
        "storageBucket": "rotativo-smart-parking.appspot.com",
        "apiKey": "AIzaSyAxRKWuDFBRPL0PJG2jpyvqpiT-h3wMdgA",
        "authDomain": "rotativo-smart-parking.firebaseapp.com",
        "messagingSenderId": "110514863393",
        "measurementId": "G-W6Q2V54YJQ"
    })),
        provideDatabase(() => getDatabase()),
        provideFirestore(() => getFirestore())
    ]
})
export class AppModule { }
