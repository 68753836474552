import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MultasService } from 'src/app/service/multas.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';

@Component({
  selector: 'app-veiculos-prefeitura',
  templateUrl: './veiculos-prefeitura.component.html',
  styleUrls: ['./veiculos-prefeitura.component.scss']
})
export class VeiculosPrefeituraComponent implements OnInit {
  colunas: string[] = ["Placa"];
  fields = ['placa'];
  public placa = '';
  veiculosData: any[] = [];
  addVehicleDiag: any;
  delet: any;
  selectedItem: any;
  selectedPlaca: any;
  filteredItems: any;
  operacoesData: any[] = [];
  showComponent: boolean = true;
  searchText: string;

  constructor(private loading: LoadingService, private message: MessageService, private multasService: MultasService) { }

  ngOnInit(): void {
    this.getVeiculosPrefeitura();
  }
  getVeiculosPrefeitura() {
    this.addVehicleDiag = false;
    this.veiculosData = [];
    this.loading.showLoading();
    this.multasService.getVeiculosPrefeitura().then((data: any) => {
      this.veiculosData = data.DATA.data;
      for (const iterator of this.veiculosData) {
        iterator.placa = this.formatLicensePlate(iterator.placa);
      }
      this.filteredItems = [...this.veiculosData];
      this.loading.hideLoading();
    }).catch((err) => {
      this.loading.hideLoading();
      this.message.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar veiculos' });
    })
  }

  postVeiculosPrefeitura() {
    this.loading.showLoading();
    this.multasService.postVeiculoPrefeitura({ placa: this.placa.toUpperCase(), idCidade: localStorage.getItem('idCidade')}).then((data: any) => {
      this.message.add({ severity: 'success', summary: 'Sucesso', detail: 'Veiculo cadastrado' });
      this.getVeiculosPrefeitura();
      this.placa = '';
      this.loading.hideLoading();
    }).catch((err) => {
      this.loading.hideLoading();
      this.placa = '';
      this.message.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao cadastrar veiculo' });
    })
  }

  deleteVeiculosPrefeitura(id: any) {
    this.loading.showLoading();
    this.multasService.deleteVeiculoPrefeitura(id).then((data: any) => {
      this.message.add({ severity: 'success', summary: 'Sucesso', detail: 'Veiculo deletado' });
      this.getVeiculosPrefeitura();
      this.loading.hideLoading();
      this.delet = false;
    }).catch((err) => {
      this.loading.hideLoading();
      this.delet = false;
      this.message.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao deletar veiculo' });
    })
  }

  handleLicensePlate(event: any) {
    let value = event.target.value;
    value = value.toUpperCase();
    if (value.length > 8) {
      value = value.substr(0, 8);
    }
    this.placa = value;
  }
  

  formatLicensePlate(value: string) {
    return value.toUpperCase();
  }

  filterItems(searchText: string) {
    this.refresh();
    this.filteredItems = this.veiculosData.filter((item: any) => {
      return Object.keys(item)
        .filter(key => key !== 'id')
        .some(key => item[key].toString().toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  refresh() {
    // Altera o valor da propriedade para recriar o componente
    this.showComponent = false;
    setTimeout(() => this.showComponent = true);
  }

  deleteVeiculo(event: any) {
    this.delet = true;
    this.selectedItem = event.id;
    this.selectedPlaca = event.placa;
  }
}
