import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { IColumn, ITableData } from 'src/app/Models/table.model';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit {

  @Input()
  item: ITableData | undefined;

  @Output() selectedData = new EventEmitter<any>();

  totalRecords: number = 0;
  loading: boolean = false;
  selectAll: boolean = false;
  selectedCustomers: any[] = [];
  dataKeys: any[] = [];
  columns: IColumn[] = [];
  tableData: any;
  tableColumns: any;
  tableColumnField: any[] = [];
  boolArray: boolean[] = [];
  constructor(private router: Router) { }

  ngOnInit(): void {
    //Recebe dados do componente pai
    this.tableData = this.item?.data;
    for (const item of this.tableData) {
      if (item?.status !== undefined) {
        item.statusBool = item.status === 1 ? true : false;
      }
    }
    //console.log("🚀 ~ GenericTableComponent ~ ngOnInit ~ this.item:", this.item)
    //console.log("🚀 ~ file: generic-table.component.ts:31 ~ GenericTableComponent ~ ngOnInit ~ this.tableData", this.tableData)
    //Quantidade de dados
    this.totalRecords = this.tableData?.length;
    //Recebe colunas do componente pai
    this.tableColumns = this.item?.colunas;
    //console.log("🚀 ~ file: generic-table.component.ts ~ line 34 ~ GenericTableComponent ~ ngOnInit ~ this.tableColumns", this.tableColumns)
    //Recebe campos dos dados.
    if (this.tableData?.length > 0) {
      this.dataKeys = Object.keys(this.tableData[0]);
      this.dataKeys = this.dataKeys.sort();
    }
    //Cria campos de acordo com as keys dos dados
    this.getColumnFields();
  }

  selectItem(value: any) {
    console.log("🚀 ~ file: generic-table.component.ts:49 ~ GenericTableComponent ~ selectItem ~ value:", value)
    this.selectedData.emit(value);
  }

  //Carrega os dados TODO: Remover setTimeout ao implementar API
  loadData(event: any) {
    let key: any;
    this.loading = true;
    key = event.sortField;
    this.tableData = this.tableData.sort((a: any, b: any) => a[key] < b[key] ? event.sortOrder ? -1 * event.sortOrder : 1 : a[key] > b[key] ? event.sortOrder : 0);
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    if (event.filters?.search?.value?.length > 0) {
      this.tableData = this.findInValues(this.item?.data, event.filters?.search.value);
    }
    else {
      this.tableData = this.item?.data;
    }
  }

  findIdString(value: string) {
    if (this.item?.config?.fields) {
      return false;
    }
    if (value.search('Id') > -1 ||
      value.search('id') > -1 ||
      value.toLocaleLowerCase().search('tipo') > -1 ||
      value.toLocaleLowerCase().search('fcmtoken') > -1 ||
      value.toLocaleLowerCase().search('username') > -1 ||
      value.toLocaleLowerCase().search('culo') > -1 &&
      value != 'Cidade' && value != 'cidade') {
      return true;
    }
    return false;
  }

  getColumnFields() {
    if (this.item?.config?.fields) {
      this.dataKeys = this.item?.config?.fields;
    }
    if (this.tableColumns) {
      for (const [index, value] of this.tableColumns?.entries()) {
        this.tableColumnField.push({
          column: this.item?.config?.columnIndex ? value.name : value,
          field: this.dataKeys[index]
        })
      }

    }
  }

  onSelectionChange(value = []) {
    this.selectAll = value.length === this.totalRecords;
    this.selectedCustomers = value;
  }

  findInValues(arr: any, value: string) {
    value = String(value).toLowerCase();
    return arr.filter((o: { [s: string]: unknown; } | ArrayLike<unknown>) =>
      Object.entries(o).some(entry =>
        String(entry[1]).toLowerCase().includes(value)
      )
    );
  }

}
