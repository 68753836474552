import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OperacoesService {

  base_url = `${environment.baseUrl}`;
  constructor(private http: HttpClient) { }

  getOperacoes() {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.base_url}/v1/admin/operacoes`, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  getAddress(latitude: number, longitude: number) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.base_url}/v1/pumatronix/address`, { latitude: latitude, longitude: longitude })
        .subscribe({
          next: (data: any) => {
            resolve(data.address);
          },
          error: (err) => {
            reject(err);
          }
        });
    });
  }

}
