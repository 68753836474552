import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ConsultaPlacasService } from 'src/app/service/consulta-placas.service';
import { ExcelService } from 'src/app/service/excel.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';

interface Agentes {
  name: string;
}

@Component({
  selector: 'app-fiscalizacao',
  templateUrl: './fiscalizacao.component.html',
  styleUrls: ['./fiscalizacao.component.scss']
})
export class FiscalizacaoComponent implements OnInit {

  dataInicio = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  dataFim = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  agentes: any[] = [];
  selectedAgentes: any;
  data: any[] = [];
  placa: any;
  searchText: string;
  filteredItems: any;
  showComponent: boolean = true;

  colunas: string[] = ["Placa", "Data", "Horário", "Resultado"];
  fields = ['placa', 'data', 'horario', 'resultado', 'id'];

  constructor(private loading: LoadingService, private messageService: MessageService, private excel: ExcelService, private consultaPlacasService: ConsultaPlacasService) {
  }

  ngOnInit(): void {
    this.loading.showLoading();
    this.data = [];
    this.consultaPlacasService.getAgentes().then((data: any) => {
      this.selectedAgentes = undefined;
      const pumatronix = {
        "nome": "Pumatronix",
        "id": "1",
        "status": 1
    }
      this.agentes = data;
      this.agentes.unshift(pumatronix);
      this.loading.hideLoading();
    }).catch((error) => {
      this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar agentes!' });
      this.loading.hideLoading();
    });
  }

  getConsultaPlacas(){
    this.loading.showLoading();
    this.consultaPlacasService.get(this.dataInicio, this.dataFim, this.selectedAgentes).then((data: any) => {
      this.loading.hideLoading();
      this.data = data;
      // orderby data e horario
      // this.data.sort((a, b) => (a.data > b.data) ? 1 : (a.data === b.data) ? ((a.horario > b.horario) ? 1 : -1) : -1 );
    }).catch((error) => {
      this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar dados!' });
      this.loading.hideLoading();
    });
  }

  filterItems(searchText: string) {
    this.refresh();
    this.filteredItems = this.data.filter((item) => {
      return Object.keys(item)
        .filter(key => key == 'placa')
        .some(key => item[key].toString().toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  refresh() {
    // Altera o valor da propriedade para recriar o componente
    this.showComponent = false;
    setTimeout(() => this.showComponent = true);
  }

  export() {
    const mappedData = this.data.map(item => {
      return {
        "Placa": item.placa,
        "Data": item.data,
        "Horário": item.horario,
        "Resultado": item.resultado
      };
    });

    this.excel.exportAsExcelFile(mappedData, 'fiscalizacao');
  }
}
