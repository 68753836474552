<section class="position-relative pt-5">
    <div class="table-div">
        <div class="filter-div mt-2 pb-0 px-1 d-flex gap-3 align-items-end ">
            <div class="me-3">
                <b class="me-1 titleCalendar">De</b>
                <input type="date" [(ngModel)]="dataInicio" class="mt-2">
            </div>
            <div class="me-2">
                <b class="me-3 titleCalendar">Até</b>
                <input type="date" [(ngModel)]="dataFim" class="mt-2">
            </div>

            <div class="me-3 mt-4">
                <button pButton pRipple label="Filtrar" (click)="getRotas()" icon="pi pi-filter"
                    class="block font-bold white-space-nowrap p-button-outlined p-button-filter"></button>
            </div>

            <div class="me-3 inline-flex align-items-center">
                <b class="me-3 titleFilter">Monitor</b>
                <p-dropdown appendTo="body" [options]="agentes" placeholder="Todos" [showClear]="true" [required]="false"
                    [(ngModel)]="selectedAgentes" optionLabel="nome" optionValue="id"></p-dropdown>

            </div>

            <!-- Exportar -->
      <p-button (onClick)="export()" label="Exportar (.csv)" class="add-button ms-auto mt-4"></p-button>
        </div>

        <!-- <app-generic-table *ngIf="creditosData.length > 0" -->
        <app-generic-table *ngIf="rotas && rotas.length > 0 && showComponent; else empty"
            [item]="{colunas: colunas, data: rotas, type: 1, config: {disableSort: true, fields: fields, paginator: false}}"
            class="p-5 pb-0"></app-generic-table>

        <p-paginator (onPageChange)="changePage($event)" [first]="0" [rows]="perPage" [totalRecords]="totalItems"
            ></p-paginator>
    </div>
</section>

<ng-template #empty>
    <div class="empty-table mt-5">
        <p class="text-center">Nenhum registro encontrado</p>
    </div>
</ng-template>
