import { Component, OnInit } from '@angular/core';
import { CidadeService } from 'src/app/service/cidade.service';
import { EstacionamentoService } from 'src/app/service/estacionamento.service';
import { MultasService } from 'src/app/service/multas.service';
import { RelatorioFinanceiroService } from 'src/app/service/relatorio-financeiro.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  estacionamentoHistorico: any;
  multasData: never[];
  totalItems: any;
  constructor(private readonly Relatorioservice: RelatorioFinanceiroService, private readonly loading: LoadingService, private readonly estacionamentoService: EstacionamentoService, private readonly multaService: MultasService, private readonly ciaddeService: CidadeService) { }
  dataInicio = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  dataFim = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  relatorioData: any;
  meiosPagamento: any;
  totalVendas: any;

  intl = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  basicData: any;
  basicOptions: any;
  data: any;
  options: any;
  cidade: any;

  ngOnInit() {
    this.getRelatorioFinaceiro();
    this.getHistoricoEstacionamento();
    this.getMultas();
    this.getCidade();
    this.getDasboard();

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.basicData = {
    };

    this.basicOptions = {
      indexAxis: 'x',
      maintainAspectRatio: false,
      aspectRatio: 1,
      plugins: {
        legend: {
          display: false,
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };

    this.data = {
    };

    this.options = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      aspectRatio: 1.5,
      plugins: {
        legend: {
          display: false,
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500
            }
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  getRelatorioFinaceiro() {
    this.loading.showLoading();
    this.Relatorioservice.getRelatorioFinanceiro(new Date(this.dataInicio).toISOString(), new Date(this.dataFim).toISOString()).subscribe({
      next: (result) => {
        this.relatorioData = result.DATA;
        this.meiosPagamento = result.DATA.meiosPagamento;
        this.totalVendas = result.DATA.totalVendas;
        this.totalVendas.app = this.intl.format(this.totalVendas.app);
        this.totalVendas.monitores = this.intl.format(this.totalVendas.monitores);
        this.totalVendas.pdv = this.intl.format(this.totalVendas.pdv);
        this.totalVendas.geral = this.intl.format(this.totalVendas.geral);
      },
      error: (error) => {
        console.log(error);
        this.loading.hideLoading();

      },
      complete: () => {
        console.log("ok");
        this.loading.hideLoading();
      }
    })
  }

  getCidade() {
    const idCidade = localStorage.getItem('idCidade');
    if (!idCidade) return;
    this.ciaddeService.getCidadeById(idCidade).then((res: any) => {
      this.cidade = res[0];
    }).catch((err) => {
      console.log("🚀 ~ file: dashboard.component.ts:68 ~ DashboardComponent ~ getMultas ~ err:", err)
      this.loading.hideLoading();
    });
  }

  getDasboard() {
    this.ciaddeService.getCidadeDashboard(new Date(this.dataInicio).toISOString(), new Date(this.dataFim).toISOString()).then((res: any) => {
      this.basicData = res.taxaOcupacaoDashboard;
      this.data = res.vendasDashboard;
      this.basicData.datasets[0].label = '';
      this.data.datasets[0].label = '';
    }).catch((err) => {
      console.log("🚀 ~ file: dashboard.component.ts:68 ~ DashboardComponent ~ getMultas ~ err:", err)
      this.loading.hideLoading();
    });

  }

  getMultas() {
    this.loading.showLoading();
    this.multasData = [];
    this.multaService.getMultas(undefined, undefined, 1, 9999, this.dataInicio, this.dataFim).then((res: any) => {
      console.log("🚀 ~ file: consulta-de-multas.component.ts:55 ~ ConsultaDeMultasComponent ~ this.multaService.getMultas ~ res:", res)
      this.multasData = res.data;
      this.totalItems = res.total;
      this.loading.hideLoading();
    }
    ).catch((err) => {
      console.log("🚀 ~ file: dashboard.component.ts:68 ~ DashboardComponent ~ getMultas ~ err:", err)
      this.loading.hideLoading();
    });
  }

  getHistoricoEstacionamento() {
    this.loading.showLoading();
    this.estacionamentoService.getEstacionamentosQuadros(new Date(this.dataInicio).toDateString(), new Date(this.dataFim).toDateString()).subscribe({
      next: (result) => {
        this.estacionamentoHistorico = result.DATA.data;
        this.estacionamentoHistorico.indiceOcupacao = this.estacionamentoHistorico.indiceOcupacao*100;
      },
      error: (error) => {
        this.loading.hideLoading();
        console.log(error)
      },
      complete: () => {
      }
    })
  }
}
