import { inject, Injectable } from '@angular/core';
import { Database, ref, onValue } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { OperacoesService } from './operacoes.service';


@Injectable({
  providedIn: 'root'
})
export class RealtimeLocationService {
  private readonly database = inject(Database);
  private readonly firestore = inject(Firestore);
  private readonly operationService = inject(OperacoesService);

  constructor() { }

  public getRealtimeLocation(): Observable<any> {
    return new Observable((observer) => {
      const refer = ref(this.database, 'geolocal');
      onValue(refer, async (snapshot) => {
        const agentes: any[] = [];
        const val = snapshot.val();
        const idCidade = localStorage.getItem('idCidade');
        const localizacoes = val ? Object.keys(val) : [];
        for (const key of localizacoes) {
          val[key].agente = await this.getAgente(key);
          if (idCidade && val[key].agente.idCidade == idCidade) {
            agentes.push(
              {
                position: { lat: val[key].latitude, lng: val[key].longitude },
                address: (await this.operationService.getAddress(val[key].latitude, val[key].longitude)),
                title: val[key].agente.nome,
                options: { icon: '/assets/images/icons/pin_agente.png' },
                animated: true,
                id: key
              }
            );
          }
        }
        observer.next(agentes);
      }, (error) => {
        console.error("🚀 ~ RealtimeLocationService ~ onValue ~ error:", error)
        observer.error(error);
      });
    });
  }

  public async getAgente(id: string): Promise<any> {
    const docRef = doc(this.firestore, 'usuarios', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data(); // Retorna os dados do documento
    } else {
      return null; // Documento não encontrado
    }
  }
}
