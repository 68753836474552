import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionService } from 'src/app/service/session.service';
import * as crypto from 'crypto-js';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  showpassword: boolean;
  passReset: any;
  confirmPass: any = '';
  confirmNum: any;
  newSenha: any;

  username: string;
  OTP: any;
  otpId: any;
  newPass: string;

  public loginForm: FormGroup = this._fb.group({
    password: ['', [Validators.required]],
    username: ['', [Validators.required]],
    salt: [0]
  });

  constructor(
    private _fb: FormBuilder,
    private readonly sessionService: SessionService,
    private loading: LoadingService,
    private router: Router,
    private messageService: MessageService
  ) { }

  public async doLogin(): Promise<void> {
    if (this.loginForm.valid) {
      this.loading.showLoading();
      try {
        let senha = this.loginForm.value.password;
        let vetorSenha = crypto.enc.Utf8.parse(senha);
        let hashSenha = crypto.SHA512(vetorSenha).toString(crypto.enc.Base64);
  
        let salt = '012345678901';
        let finalSalt = salt.substring(1, 3) + salt.substring(4, 8) + salt.substring(9, 10) + salt.substring(11, 12);
        let vetorSalt = crypto.enc.Utf8.parse(finalSalt);
  
        let finalBuffer = crypto.enc.Base64.parse(hashSenha).concat(vetorSalt);
        let loginString = crypto.SHA512(finalBuffer).toString(crypto.enc.Base64);
  
        await this.sessionService.login(this.loginForm.value.username, loginString, salt).then((result: any) => {
          const firebaseUser = result.customToken.user;
          localStorage.setItem('user', JSON.stringify(firebaseUser));
          localStorage.setItem('token', firebaseUser.stsTokenManager.accessToken);
          localStorage.setItem('refreshToken', firebaseUser.stsTokenManager.refreshToken);
          localStorage.setItem('tipoUsuario', result.userData.tipoUsuario);
          localStorage.setItem('idCidade', result.userData.idCidade);
          sessionStorage.setItem('user', JSON.stringify(result.userData));
          this.loading.hideLoading();
          this.messageService.add({ severity: 'success', summary: 'Login realizado com sucesso', detail: '' });
          this.router.navigate(['/sistema/dashboard']);
        }).catch((e) => {
          console.log(e);
          this.loading.hideLoading();
          this.messageService.add({ severity: 'error', summary: 'Erro ao fazer login', detail: 'Usuário ou senha incorretos' });
        })
      } catch (error) {
        console.log("🚀 ~ LoginComponent ~ doLogin ~ error:", error)
        this.loading.hideLoading();
        this.messageService.add({ severity: 'error', summary: 'Erro ao fazer login', detail: 'Usuário ou senha incorretos' });
      }
    } else {
      this.loading.hideLoading();
      this.messageService.add({ severity: 'error', summary: 'Erro ao fazer login', detail: 'Usuário ou senha incorretos' });
    }
  }

  phoneLength() {
    const phone = this.username.replace(/\D/g, '');
    return phone.length;
  }

  showHidePassword(): void {
    const x: any = document.getElementById('inputPassword');
    if (x.type === 'password') {
      this.showpassword = true;
      x.type = 'text';
    } else {
      this.showpassword = false;
      x.type = 'password'
    }
  }

  sendNum(){
    this.loading.showLoading();
    this.sessionService.otpRequest(this.username).pipe(take(1)).subscribe((result: any) => {
      console.log("🚀 ~ file: login.component.ts:102 ~ LoginComponent ~ this.sessionService.otpRequest ~ result:", result);
      if (result.STATUS === "SUCCESS") {
        this.loading.hideLoading();
        this.messageService.add({ severity: 'success', summary: 'Código enviado com sucesso', detail: '' });
        this.newSenha = true;
        this.otpId = result.DATA.otpID;
      }
      else{
        this.loading.hideLoading();
        this.messageService.add({ severity: 'error', summary: 'Erro ao enviar código', detail: '' });
      }
    });
  }

  requestOTP() {

  }

  resetPassword() {
    this.loading.showLoading();
    this.sessionService.otpValidate({userName: this.username, otp: this.OTP, newPassword: this.newPass, otpID: this.otpId}).pipe(take(1)).subscribe((result: any) => {
      console.log("🚀 ~ file: login.component.ts:102 ~ LoginComponent ~ this.sessionService.otpRequest ~ result:", result);
      this.loading.hideLoading();
      if (result.STATUS === "SUCCESS") {
        this.messageService.add({ severity: 'success', summary: 'Senha alterada com sucesso', detail: '' });
        this.passReset = false;
        this.newSenha = false;
      }
      else{
        this.messageService.add({ severity: 'error', summary: 'Erro ao alterar senha', detail: '' });
        this.loading.hideLoading();
      }
    })
  }

}
