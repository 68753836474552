import { Component, OnInit } from '@angular/core';
import { CreditoService } from 'src/app/service/credito.service';
import { ExcelService } from 'src/app/service/excel.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-consulta-do-dia-de-trabalho',
  templateUrl: './consulta-do-dia-de-trabalho.component.html',
  styleUrls: ['./consulta-do-dia-de-trabalho.component.scss']
})
export class ConsultaDoDiaDeTrabalhoComponent implements OnInit {
  agente: any;
  agentList: any;
  value = {
    credito: 0,
    dinheiro: 0,
    debito: 0,
    pix: 0
  }
  data: Date = new Date();
  constructor(private readonly userService: UserService, private readonly creditoService: CreditoService, private readonly excel: ExcelService) { }

  ngOnInit(): void {
    this.getUsuarios();
    this.getLog();
  }

  getUsuarios(){
    this.agentList = [];
    this.userService.getUsers().then((data: any) => {
      this.agentList = data;
    });
  }

  getLog(){
    this.creditoService.getCreditoLogByAgente(this.agente, this.data.toISOString()).then((data: any) => {
      this.value = data;
    });
  }

  export(){
    this.excel.exportAsExcelFile([this.value], 'credito Log');
  }

}
