<section class="position-relative">
  <div class="filter-div p-3 pb-0 px-1 d-flex align-items-center mt-4 mb-4">
    <div class="me-3 inline-flex align-items-center">
      <b class="me-1 titleCalendar">De</b>
      <input type="date" [(ngModel)]="dataInicio">
    </div>
    <div class="me-2 inline-flex align-items-center">
      <b class="me-3 titleCalendar">Até</b>
      <input type="date" [(ngModel)]="dataFim">
    </div>

    <div class="me-3 inline-flex align-items-center" *ngIf="data && data.length > 0">
      <b class="me-3 titleFilter">Placa</b>
      <span class="p-input-icon-right mt-5 mb-2 md:mt-0 md:mb-0 w-full">
        <input [(ngModel)]="searchText" (ngModelChange)="filterItems(searchText)" type="text"
          class="p-inputtext w-full  surface-border" placeholder="Digite" />
      </span>
    </div>

    <div class="me-3 inline-flex align-items-center ">
      <b class="me-2 titleFilter">Agente</b>
      <p-dropdown [options]="agentes" [(ngModel)]="selectedAgentes" placeholder="Selecione..." optionLabel="nome" optionValue="id"></p-dropdown>

    </div>

    <div class="me-3">
      <button [disabled]="!selectedAgentes" pButton pRipple label="Filtrar" (click)="getConsultaPlacas()" icon="pi pi-filter"
        class="block font-bold white-space-nowrap p-button-outlined p-button-filter"></button>

    </div>
    <!-- Exportar -->
    <p-button (onClick)="export()" label="Exportar (.csv)" class="add-button ms-auto"></p-button>
  </div>

  <!-- Type 1: Status, Type 0: Sem status -->
  <div class="table-div">

    <app-generic-table *ngIf="data.length > 0 && showComponent; else empty"
      [item]="{colunas: colunas, data: searchText && searchText.length > 0 ? filteredItems : data, type: 1, config: {disableSort: true, fields: fields}}"
      class="p-5 pb-0"></app-generic-table>
  </div>
</section>

<ng-template #empty>
  <div class="empty-table">
    <p class="text-center">Nenhum registro encontrado</p>
  </div>
</ng-template>
