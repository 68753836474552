<section class="position-relative pt-5">
    <div class="filter-div pb-3 d-flex mt-3 no-print">
        <div class="me-3">
            <b class="me-3 titleCalendar">De</b>
            <input type="date" [(ngModel)]="dataInicio">
        </div>
        <div class="me-3">
            <b class="me-3 titleCalendar">Até</b>
            <input type="date" [(ngModel)]="dataFim">
        </div>

        <div class="me-3">
            <button pButton pRipple label="Filtrar" (click)="ngOnInit()" icon="pi pi-filter"
                class="block font-bold white-space-nowrap p-button-outlined p-button-filter"></button>

        </div>
        <!-- Exportar -->
        <p-button (onClick)="export()" label="Exportar" class="add-button ms-auto"></p-button>
    </div>

    <!-- Type 1: Status, Type 0: Sem status -->
    <div class="table-div" id="contentToConvert">
        <div class="grid py-3">
            <div class="md:col-6 lg:col-3 sm:col-12">
                <div class="cardVendas p-3">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-title mb-3">Total de Vagas Operacionais</span>
                        </div>

                    </div>
                    <span class="text-footer">{{relatorioData?.totalVagasOperacionais}}</span>
                </div>
            </div>
            <div class="md:col-6 lg:col-3 sm:col-12">
                <div class="cardVendas p-3">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-title mb-3">Faturamento Esperado</span>
                            <p class="text-footer">(100% da Ocupação)</p>
                        </div>

                    </div>
                    <span class="text-footer">{{relatorioData?.faturamentoEsperado | toReal}}</span>
                </div>
            </div>
            <div class="md:col-6 lg:col-3 sm:col-12">
                <div class="cardVendas p-3">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-title mb-3">Estacionamento Utilizado</span>
                        </div>

                    </div>
                    <span class="text-footer">{{relatorioData?.realizado | toReal}}</span>
                </div>
            </div>
            <div class="md:col-6 lg:col-3 sm:col-12">
                <div class="cardVendas p-3">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-title mb-3">Resultado</span>
                        </div>

                    </div>
                    <span class="text-footer">{{relatorioData?.resultado ? (relatorioData.resultado) : 00}} %</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="table-inf md:col-6 lg:col-3">
                <div class="table-title py-2 px-4">
                    <a class="title-brand">Total Vendas - Valor Bruto</a>
                </div>

                <div class="grid mt-3">
                    <div class="lg:col-12">
                        <div class="surface-section">
                            <ul class="list-none p-0 m-0">
                                <li style="height: 4rem;"
                                    class="flex gap-4 align-items-center py-3 px-2 border-bottom-1 border-bottom-1 surface-border justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start">Monitores</div>
                                    <div
                                        class="description text-start w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3 mtitle h-18px">
                                        {{totalVendas?.monitores | toReal}}</div>
                                    <div class="mtitle h-18px">{{totalVendas?.monitores === 0 ? 0 :
                                        getPercent(totalVendas?.monitores)}}%
                                    </div>
                                </li>

                                <li style="height: 4rem;"
                                    class="flex gap-4 align-items-center py-3 px-2 border-bottom-1 surface-border justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start">App</div>
                                    <div
                                        class="description text-start w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{totalVendas?.app | toReal}}</div>
                                    <div class="mtitle h-18px">{{totalVendas?.app === 0 ? 0 : getPercent(totalVendas?.app)}}%
                                    </div>
                                </li>
                                <li style="height: 4rem;"
                                    class="flex gap-4 align-items-center py-3 px-2 border-bottom-1 surface-border justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start">PDV</div>
                                    <div
                                        class="description text-start w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{totalVendas?.pdv | toReal}}
                                    </div>
                                    <div class="mtitle h-18px">{{totalVendas?.pdv === 0 ? 0 : getPercent(totalVendas?.pdv)}}%
                                    </div>
                                </li>

                                <li style="height: 4rem;"
                                    class="flex gap-4 align-items-center py-3 px-2 border-bottom-1 surface-border justify-content-between">
                                    <div class="title w-6 md:w-8 mtitle text-start h-18px">Total Geral</div>
                                    <div
                                        class="title text-start w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{totalVendas?.geral
                                        | toReal}}
                                    </div>
                                    <div class="mtitle">100%</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-inf md:col-6 lg:col-3">
                <div class="table-title py-2 px-4">
                    <a class="title-brand">Meio de Pagamento</a>
                </div>

                <div class="grid mt-3">
                    <div class="lg:col-12">
                        <div class="surface-section">
                            <ul class="list-none p-0 m-0">
                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start mw-t">Cartão de Débito</div>
                                    <div class="w-full md:w-4 md:flex-order-0 flex-order-1 description mtitle h-18px">
                                        {{meiosPagamento?.debito | toReal}}</div>
                                    <div class="mtitle h-18px">{{meiosPagamento?.debito === 0 ? 0 :
                                        getPercent(meiosPagamento?.debito)}}%
                                    </div>
                                </li>

                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start mw-t">Cartão de Crédito</div>
                                    <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{meiosPagamento?.credito | toReal}}</div>
                                    <div class="mtitle h-18px">{{meiosPagamento?.credito === 0 ? 0 :
                                        getPercent(meiosPagamento?.credito)}}%
                                    </div>
                                </li>

                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 border-bottom-1 surface-border justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start mw-t">Dinheiro</div>
                                    <div
                                        class="description w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3 mtitle h-18px">
                                        {{meiosPagamento?.dinheiro | toReal}}</div>
                                    <div class="mtitle h-18px">{{meiosPagamento?.dinheiro === 0 ? 0 :
                                        getPercent(meiosPagamento?.dinheiro)}}%
                                    </div>
                                </li>
                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 border-bottom-1 surface-border justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start mw-t">Pix</div>
                                    <div
                                        class="description w-full md:w-4 md:flex-order-0 flex-order-1 line-height-3 mtitle h-18px">
                                        {{meiosPagamento?.pix | toReal}}</div>
                                    <div class="mtitle h-18px">{{meiosPagamento?.pix === 0 ? 0 :
                                        getPercent(meiosPagamento?.pix)}}%</div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-inf md:col-6 lg:col-3">
                <div class="table-title py-2 px-4">
                    <a class="title-brand">Remuneração Outorga</a>
                </div>

                <div class="grid mt-3">
                    <div class="lg:col-12">
                        <div class="surface-section">
                            <ul class="list-none p-0 m-0">


                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start">Total Vendas - Valor Bruto
                                    </div>
                                    <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{totalVendas?.geral | toReal}}
                                    </div>

                                </li>

                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start">Percentual Outorga</div>
                                    <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{relatorioData?.percOutorga}} %
                                    </div>

                                </li>

                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start">Remuneração Outorga</div>
                                    <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{totalVendas?.geral * (relatorioData?.percOutorga / 100)
                                        | toReal}}
                                    </div>

                                </li>

                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
                                    <div class="title w-6 md:w-8 mtitle text-start">Saldo Líquido</div>
                                    <div class="title w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{totalVendas?.geral - (totalVendas?.geral * (relatorioData?.percOutorga /
                                        100))
                                        | toReal}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-inf md:col-6 lg:col-3">
                <div class="table-title py-2 px-4">
                    <a class="title-brand">Administração</a>
                </div>

                <div class="grid mt-3">
                    <div class="lg:col-12">
                        <div class="surface-section">
                            <ul class="list-none p-0 m-0">

                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start">Total Vendas - Valor Bruto
                                    </div>
                                    <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{totalVendas?.geral
                                        | toReal}}</div>

                                </li>
                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start">Percentual Administração</div>
                                    <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{relatorioData?.administracao}} %
                                    </div>

                                </li>


                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
                                    <div class="w-6 md:w-8 description mtitle text-start">Receita Administração</div>
                                    <div class="description w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{totalVendas?.geral * (relatorioData?.administracao / 100)
                                        | toReal}}</div>

                                </li>

                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
                                    <div class="title w-6 md:w-8 mtitle text-start">Saldo Líquido</div>
                                    <div class="title w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{totalVendas?.geral - (totalVendas?.geral * (relatorioData?.administracao /
                                        100))
                                        | toReal}}</div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-inf md:col-6 lg:col-3">
                <div class="table-title py-2 px-4">
                    <a class="title-brand">Resultado Líquido</a>
                </div>

                <div class="grid mt-3">
                    <div class="lg:col-12">
                        <div class="surface-section">
                            <ul class="list-none p-0 m-0">
                                <li style="height: 4rem;"
                                    class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap justify-content-between">
                                    <div class="title w-6 md:w-8 mtitle text-start">Saldo Líquido</div>
                                    <div class="title w-full md:w-4 md:flex-order-0 flex-order-1 mtitle h-18px">
                                        {{ totalVendas?.geral - ((totalVendas?.geral * (relatorioData?.administracao /
                                        100) + totalVendas?.geral * (relatorioData?.percOutorga / 100))) | toReal}}
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>