<section class="position-relative pt-5">
  <div class="page-title">
    <!-- <h5 class="fw-bold">Usuários</h5> -->
  </div>
  <!-- Type 1: Status, Type 0: Sem status -->
  <div class="table-div">

    <div class="filter-div pt-3 pb-3 d-flex">
      <div class="flex">
        <span class="p-input-icon-left mr-3">
          <input pInputText type="text" [(ngModel)]="searchText" (ngModelChange)="filterItems(searchText)" placeholder="Pesquisar" field="search" />
        </span>
        <p-button icon="pi pi-search" [rounded]="true" severity="success" styleClass="p-button-search" />

      </div>
      <p-button (onClick)="addVehicleDiag = true" label="Adicionar Placa" icon="pi pi-plus-circle"
        class="add-button ms-auto p-button-success" iconPos="left"></p-button>
    </div>
    <div class="position-relative">

        <p-table [value]="filteredItems">
          <ng-template pTemplate="header">
            <tr>
              <th style="background: transparent;">Placa</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{item.placa}}</td>
              <td [style]="{'text-align': 'end'}">
                <i class="pi pi-trash" (click)="deleteVeiculo(item)" style="cursor: pointer"></i>
              </td>
            </tr>
          </ng-template>
        </p-table>
    </div>
  </div>
</section>

<p-dialog header="Nova Placa" styleClass="modalUserForm" [(visible)]="addVehicleDiag" [modal]="true" [draggable]="false"
  [resizable]="false">
  <div class="py-3">
    <label class="form-label d-block">Placa</label>
    <input placeholder="Placa" class="form-control" [(ngModel)]="placa" type="text" pInputText (maxlength)="8"
      (keyup)="handleLicensePlate($event)" [value]="placa" />
  </div>

  <ng-template pTemplate="footer">
    <button (click)="addVehicleDiag = false" pButton pRipple type="button" label="Cancelar" icon="pi pi-times"
      class="p-button-danger p-button-cancel"></button>
    <button pButton pRipple type="button" label="Salvar" (click)="postVeiculosPrefeitura()" class="p-button-save"
      [disabled]="placa.length === 0"></button>

    <!-- <p-button (onClick)="newUser()" [disabled]="addUserForm.invalid" label="Salvar" icon="pi pi-plus-circle"
    class="add-button float-end" iconPos="left"></p-button> -->
  </ng-template>

</p-dialog>


<p-dialog [(visible)]="delet" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
  [style]="{width: '40vw'}">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle"
        style="width:32px;height:32px">
        <i class="pi pi-info text-lg"></i>
      </span>
      <span class="font-medium text-2xl text-900">Deletar</span>
    </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
    Gostaria de deletar a placa {{selectedPlaca}}?
  </p>
  <ng-template pTemplate="footer">
    <div class=" border-top-1 surface-border pt-3">
      <button pButton pRipple icon="pi pi-times" (click)="delet = false" label="Cancelar"
        class="p-button-text"></button>
      <button pButton pRipple icon="pi pi-check" class="p-button-danger"
        (click)="deleteVeiculosPrefeitura(selectedItem);" label="Deletar"></button>
    </div>
  </ng-template>
</p-dialog>

<ng-template #empty>
  <div class="empty-table">
    <p class="text-center">Nenhum registro encontrado</p>
  </div>
</ng-template>