import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService  {
    constructor(public sessionService: SessionService, public router: Router, public message: MessageService) { }
    canActivate(): boolean {
        if (!this.sessionService.isAuthenticated()) {
            // this.message.add({ severity: 'error', summary: 'Erro', detail: 'Você não está logado!' });
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
}
