import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ConsultaPlacasService } from 'src/app/service/consulta-placas.service';
import { ExcelService } from 'src/app/service/excel.service';
import { MultasService } from 'src/app/service/multas.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';

@Component({
  selector: 'app-consulta-de-multas',
  templateUrl: './consulta-de-multas.component.html',
  styleUrls: ['./consulta-de-multas.component.css']
})
export class ConsultaDeMultasComponent implements OnInit {

  public addUserDiag: boolean = false;
  public userType: any = 'cliente';
  colunas: string[] = ["Código da Infração", "Agente", "Localização", "Observação", "Placa", "Data do Registro", "Horário", "Foto"];
  fields = ['codigoInfracao', 'agente', 'localizacao', 'observacao', 'placa', 'dataCriacaoRegistro', 'horario', 'fotoUrl', 'id'];
  columnClasses = ['default-class', 'default-class', 'default-class', 'left-align', 'default-class', 'default-class', 'default-class', 'default-class'];
  dataInicio = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  dataFim = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  public multasData: any[] = [];
  filteredItems: any;
  showComponent: boolean = true;
  searchText: string;
  page: number = 1;
  perPage: number = 10;
  agentes: any[] = [];
  selectedAgentes: any;
  totalItems: number = 0;
  limitObservacao(observacao: string): string {
    const limit = 310;
    return observacao.length > limit ? observacao.substring(0, limit) + '...' : observacao;
  }

  constructor(private readonly multaService: MultasService, private loading: LoadingService, private messageService: MessageService, private excel: ExcelService, private route: ActivatedRoute, private consultaPlacasService: ConsultaPlacasService) { }

  ngOnInit(): void {
    this.page = 1;
    // this.getMulta();
    this.route.params.subscribe((params: Params) => {
      this.page = params['page'] ? parseInt(params['page'], 10) : 1;
      this.perPage = params['perPage'] ? parseInt(params['perPage'], 10) : 10;
    });
    this.consultaPlacasService.getAgentes().then((data: any) => {
      const fixedAgentes = [
        {
          nome: 'Todos'
        },
        {
          nome: 'Pumatronix',
          id: '1'
        }
      ]
      this.agentes = [...fixedAgentes, ...data];
      this.getMultas();
    }).catch((error) => {
      this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar agentes!' });
      this.loading.hideLoading();
    });
  }

  changePage(page: any) {
    this.page = page.page + 1;
    this.getMultas(true);
  }

  getMultas(isPage?: boolean) {
    if (!isPage) {
      this.page = 1;
    }
    console.log("🚀 ~ file: consulta-de-multas.component.ts:58 ~ ConsultaDeMultasComponent ~ getMultas ~ this.page:", this.page)
    this.loading.showLoading();
    this.multasData = [];
    this.multaService.getMultas(this.selectedAgentes, this.searchText, this.page, this.perPage, this.dataInicio, this.dataFim).then((res: any) => {
      console.log("🚀 ~ file: consulta-de-multas.component.ts:55 ~ ConsultaDeMultasComponent ~ this.multaService.getMultas ~ res:", res)
      this.multasData = res.data;
      this.totalItems = res.total;
      this.loading.hideLoading();
    }
    ).catch((err) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao carregar créditos!', detail: err });
      this.loading.hideLoading();
    });

  }

  get multasDataLimitadas() {
    return this.multasData.map(item => ({
      ...item,
      observacao: this.limitObservacao(item.observacao)
    }));
  }


  async export() {
    this.loading.showLoading();
    const allMultas = (await this.multaService.getMultas(this.selectedAgentes, this.searchText, this.page, this.totalItems, this.dataInicio, this.dataFim) as any).data;
    const mappedData = allMultas.map((item: any) => {
      return {
        "Código da Infração": item.codigoInfracao,
        "Agente": item.agente,
        "Localização": item.localizacao,
        "Observação": item.observacao,
        "Placa": item.placa,
        "Data do Registro": item.dataCriacaoRegistro,
        "Horário": item.horario,
        "Foto": item.fotoUrl,
      };
    });
    this.loading.hideLoading();
    this.excel.exportAsExcelFile(mappedData, 'infracoes');
  }

  filterItems(searchText: string) {
    this.refresh();
    this.filteredItems = this.multasData.filter((item: any) => {
      return Object.keys(item)
        .filter(key => key === 'cpf')
        .some(key => item[key].toString().toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  refresh() {
    // Altera o valor da propriedade para recriar o componente
    this.showComponent = false;
    setTimeout(() => this.showComponent = true);
  }

}
