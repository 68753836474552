import { Router } from '@angular/router';
import { LoadingService } from './../../shared/loading/loading.service.component';
import { UserService } from './../../service/user.service';
import { CidadeService } from './../../service/cidade.service';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { OperacoesService } from 'src/app/service/operacoes.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-operacoes',
  templateUrl: './operacoes.component.html',
  styleUrls: ['./operacoes.component.scss']
})
export class OperacoesComponent {
  dateIni: Date;
  dateFim: Date;
  public addUserDiag: boolean = false;
  colunas: string[] = ["Operação", "Vlr. Hora (Carro)", "Vlr. Hora (Moto)", "Per. Mínimo", "Per. Máximo", "Total Vagas", "Comissão PDV", "CNPJ", "Perc. Outorga", "Perc. Admin", "Situação"];
  fields = ['nome', 'valorHoraCarro', 'valorHoraMoto', 'periodoMinimo', 'periodoMaximo', 'totalVagas', 'comissaoPDV', 'cnpj', 'percRemuneracao', 'percAdmin', 'status'];
  operacoesData: any[] = [];
  searchText: string;
  filteredItems: any;
  showComponent: boolean = true;


  public addUserForm: FormGroup = this._fb.group({
    nome: [null, [Validators.required]],
    valorHoraCarro: [null, [Validators.required]],
    valorHoraMoto: [null, [Validators.required]],
    periodoMinimo: [null, [Validators.required]],
    periodoMedio1: [null, [Validators.required]],
    periodoMedio2: [null, [Validators.required]],
    periodoMaximo: [null, [Validators.required]],
    estado: 'MG',
    totalVagas: [null, [Validators.required]],
    totalVagasMoto: [null, [Validators.required]],
    totalVagasCarro: [null, [Validators.required]],
    comissaoPDV: [null, [Validators.required]],
    cnpj: [null, [Validators.required]],
    percRemuneracao: [null, [Validators.required]],
    percAdmin: [null, [Validators.required]],
    horariosFuncionamento: this._fb.array([
      this.criarDiaDaSemanaFormGroup('Segunda'),
      this.criarDiaDaSemanaFormGroup('Terça'),
      this.criarDiaDaSemanaFormGroup('Quarta'),
      this.criarDiaDaSemanaFormGroup('Quinta'),
      this.criarDiaDaSemanaFormGroup('Sexta'),
      this.criarDiaDaSemanaFormGroup('Sábado'),
      // this.criarDiaDaSemanaFormGroup('Domingo')
    ]) as any,
  });

  public addUserFormBackup = this._fb.group({
    nome: [null, [Validators.required]],
    valorHoraCarro: [null, [Validators.required]],
    valorHoraMoto: [null, [Validators.required]],
    periodoMinimo: [null, [Validators.required]],
    periodoMedio1: [null, [Validators.required]],
    periodoMedio2: [null, [Validators.required]],
    periodoMaximo: [null, [Validators.required]],
    estado: 'MG',
    totalVagas: [null, [Validators.required]],
    totalVagasMoto: [null, [Validators.required]],
    totalVagasCarro: [null, [Validators.required]],
    comissaoPDV: [null, [Validators.required]],
    cnpj: [null, [Validators.required]],
    percRemuneracao: [null, [Validators.required]],
    percAdmin: [null, [Validators.required]],
    horariosFuncionamento: this._fb.array([
      this.criarDiaDaSemanaFormGroup('Segunda'),
      this.criarDiaDaSemanaFormGroup('Terça'),
      this.criarDiaDaSemanaFormGroup('Quarta'),
      this.criarDiaDaSemanaFormGroup('Quinta'),
      this.criarDiaDaSemanaFormGroup('Sexta'),
      this.criarDiaDaSemanaFormGroup('Sábado'),
      this.criarDiaDaSemanaFormGroup('Domingo')
    ]) as any,
  });

  public diasSemana = this.addUserForm.get('horariosFuncionamento') as any;
  edit: boolean;
  updateValues: any;

  constructor(private _fb: FormBuilder,
    private readonly cidadeService: CidadeService, private readonly UserService: UserService, private loading: LoadingService, private readonly operacoesService: OperacoesService, private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {
    this.getOperacoes();

    this.addUserForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        if (value.periodoMinimo < 30) {
          this.addUserForm.get('periodoMinimo')?.setValue(30, { emitEvent: false });
          this.messageService.add({ severity: 'warn', summary: 'Atenção!', detail: 'O período mínimo não pode ser menor que 30 minutos.' });
        }
        if (value.periodoMedio1 < value.periodoMinimo) {
          this.addUserForm.get('periodoMedio1')?.setValue(value.periodoMinimo, { emitEvent: false });
          this.messageService.add({ severity: 'warn', summary: 'Atenção!', detail: 'O período médio 1 não pode ser menor que o período mínimo.' });
        }
        if (value.periodoMedio2 < value.periodoMedio1) {
          this.addUserForm.get('periodoMedio2')?.setValue(value.periodoMedio1, { emitEvent: false });
          this.messageService.add({ severity: 'warn', summary: 'Atenção!', detail: 'O período médio 2 não pode ser menor que o período médio 1.' });
        }
        if (value.periodoMaximo < value.periodoMedio2) {
          this.addUserForm.get('periodoMaximo')?.setValue(value.periodoMedio2, { emitEvent: false });
          this.messageService.add({ severity: 'warn', summary: 'Atenção!', detail: 'O período máximo não pode ser menor que o período médio 2.' });
        }
      });
  }

  criarDiaDaSemanaFormGroup(diaDaSemana: string): FormGroup {
    let dia = 0;
    switch (diaDaSemana) {
      case 'Segunda':
        dia = 1;
        break;
      case 'Terça':
        dia = 2;
        break;
      case 'Quarta':
        dia = 3;
        break;
      case 'Quinta':
        dia = 4;
        break;
      case 'Sexta':
        dia = 5;
        break;
      case 'Sábado':
        dia = 6;
        break;
      case 'Domingo':
        dia = 0;
        break;
    }
    return this._fb.group({
      diaDaSemana: diaDaSemana,
      horaInicio: ['', Validators.required],
      diaSemana: dia,
      horaFim: ['', Validators.required],
      checked: [null]
    });
  }

  getOperacoes() {
    this.loading.showLoading();
    this.operacoesData = [];
    this.operacoesService.getOperacoes().then((res: any) => {
      this.operacoesData = res;
      console.log("🚀 ~ file: operacoes.component.ts:44 ~ OperacoesComponent ~ this.operacoesService.getOperacoes ~ this.operacoesData:", this.operacoesData)
      this.loading.hideLoading();
    }
    ).catch((err) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao carregar operações!', detail: err });
      this.loading.hideLoading();
    });
  }

  getData() {
    console.log("🚀 ~ file: operacoes.component.ts:44 ~ OperacoesComponent ~ this.operacoesService.getOperacoes ~ this.operacoesData:", this.addUserForm.value);
  }

  async createCidade() {
    if (this.edit) {
      this.loading.showLoading();

      this.cidadeService.updateCidades(this.updateValues.id, this.addUserForm.value).then(() => {
        this.addUserDiag = false;
        this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'Cidade atualizada com sucesso.' });
        this.ngOnInit();
        this.loading.hideLoading();
      }).catch(() => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Erro ao atualizar cidade.' });
        this.loading.hideLoading();
      });
      return;
    }
    this.loading.showLoading();
    if (this.addUserForm.valid) {
      const data = {
        nome: this.addUserForm.value.nome,
        valorHoraCarro: this.addUserForm.value.valorHoraCarro,
        valorHoraMoto: this.addUserForm.value.valorHoraMoto,
        periodoMinimo: this.addUserForm.value.periodoMinimo,
        periodoMedio1: this.addUserForm.value.periodoMedio1,
        periodoMedio2: this.addUserForm.value.periodoMedio2,
        periodoMaximo: this.addUserForm.value.periodoMaximo,
        totalVagas: this.addUserForm.value.totalVagasCarro + this.addUserForm.value.totalVagasMoto,
        totalVagasMoto: this.addUserForm.value.totalVagasMoto,
        totalVagasCarro: this.addUserForm.value.totalVagasCarro,
        horariosFuncionamento: this.addUserForm.value.horariosFuncionamento,
        estado: this.addUserForm.value.estado,
        comissaoPDV: this.addUserForm.value.comissaoPDV,
        cnpj: this.addUserForm.value.cnpj,
        percRemuneracao: this.addUserForm.value.percRemuneracao,
        percAdmin: this.addUserForm.value.percAdmin,
        status: 1
      };
      await this.cidadeService.createCidade(data).then(result => {
        console.log(result)
        this.loading.hideLoading();
        this.addUserDiag = false;
        this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'Cidade cadastrada com sucesso.' });
        this.getOperacoes();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }).catch(async (error: any) => {
        this.loading.hideLoading();
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Erro ao cadastrar cidade.' });
      });
    }
  }

  converterStringParaNumero(valorString: string): number | null {
    // Remove caracteres indesejados da string, como espaços em branco e sinais de porcentagem
    const valorLimpo = valorString.replace(/[^\d.,-]/g, '').replace(' min', '');


    // Substitui a vírgula pelo ponto
    const valorNumericoString = valorLimpo.replace(',', '.');

    // Verifica se a string resultante é um número válido
    const valorNumerico = Number(valorNumericoString);

    if (isNaN(valorNumerico)) {
      return null;
    }

    return valorNumerico;
  }

  resetForm() {
    this.addUserDiag = true;
    this.edit = false;
    this.addUserForm.patchValue({
      nome: '',
      valorHoraCarro: '',
      valorHoraMoto: '',
      periodoMinimo: '',
      periodoMedio1: '',
      periodoMedio2: '',
      periodoMaximo: '',
      totalVagas: '',
      estado: 'MG',
      comissao: '',
      horariosFuncionamento: [
        {
          diaDaSemana: 'Segunda',
          horaInicio: '',
          horaFim: ''
        },
        {
          diaDaSemana: 'Terça',
          horaInicio: '',
          horaFim: ''
        },
        {
          diaDaSemana: 'Quarta',
          horaInicio: '',
          horaFim: ''
        },
        {
          diaDaSemana: 'Quinta',
          horaInicio: '',
          horaFim: ''
        },
        {
          diaDaSemana: 'Sexta',
          horaInicio: '',
          horaFim: ''
        },
        {
          diaDaSemana: 'Sábado',
          horaInicio: '',
          horaFim: ''
        },
        {
          diaDaSemana: 'Domingo',
          horaInicio: '',
          horaFim: ''
        }
      ]
    });
  }

  updateUser(data?: any) {
    if (data.value) {
      console.log("🚀 ~ file: usuarios.component.ts:195 ~ UsuariosComponent ~ updateUser ~ data:", data)
      this.loading.showLoading();
      this.cidadeService.updateCidades(data.value.id, { status: data.value.data }).then(() => {
        this.messageService.add({ severity: 'success', summary: 'Operação atualizada com sucesso', detail: '' });
        this.loading.hideLoading();
      }).catch((err) => {
        this.messageService.add({ severity: 'error', summary: 'Erro ao atualizar operação!', detail: err });
        this.loading.hideLoading();
      });
    }
    else {
      if (this.router.routerState.snapshot.url !== '/sistema/usuarios/clientes') {
        this.edit = true;
      }
      this.addUserDiag = !this.addUserDiag;
      const dados = data;
      dados.percAdmin = this.converterStringParaNumero(dados.percAdmin);
      dados.percRemuneracao = this.converterStringParaNumero(dados.percRemuneracao);
      // dados.comissaoPDV = this.converterStringParaNumero(dados.comissaoPDV);
      dados.valorHoraCarro = this.converterStringParaNumero(dados.valorHoraCarro);
      dados.valorHoraMoto = this.converterStringParaNumero(dados.valorHoraMoto);
      dados.periodoMedio1 = this.converterStringParaNumero(dados.periodoMedio1);
      dados.periodoMedio2 = this.converterStringParaNumero(dados.periodoMedio2);
      dados.periodoMinimo = this.converterStringParaNumero(dados.periodoMinimo);
      dados.periodoMaximo = this.converterStringParaNumero(dados.periodoMaximo);
      dados.comissaoPDV = this.converterStringParaNumero(dados.comissaoPDV);
      this.addUserForm.reset();

      console.log("🚀 ~ file: operacoes.component.ts:270 ~ OperacoesComponent ~ updateUser ~ dados:", dados);
      (this.addUserForm as FormGroup).patchValue({
        ...data
      });
      this.updateValues = dados;
    }
  }

  filterItems(searchText: string) {
    this.refresh();
    this.filteredItems = this.operacoesData.filter((item: any) => {
      return Object.keys(item)
        .filter(key => key !== 'id')
        .some(key => item[key].toString().toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  refresh() {
    // Altera o valor da propriedade para recriar o componente
    this.showComponent = false;
    setTimeout(() => this.showComponent = true);
  }

  onValueChange(event: any) {
    const input = event.target;
    if (input.type === 'number' && input.value !== '' && input.value < 0) {
      input.value = '';
    }
  }

  onChangeCheckBox(event: any) {
    const value = event.checked;
    if (value) {
      const form = this.addUserForm.get('horariosFuncionamento');
      const inicio = form?.value[0]['horaInicio'];
      const fim = this.addUserForm.get('horariosFuncionamento')?.value[0]['horaFim'];
      const arrays = form?.value as any[];
      const newArray = [];
      for (let index = 0; index < arrays.length; index++) {
        const element = arrays[index];
        const item = Object.assign(element, { "horaInicio": inicio, 'horaFim': fim });
        newArray.push(item)
      }
      this.addUserForm.get('horariosFuncionamento')?.setValue(newArray)
    }
  }

}
