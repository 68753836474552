<section class="position-relative pt-5">
  <div class="page-title">
    <!-- <h5 class="fw-bold">Usuários</h5> -->
  </div>
  <!-- Type 1: Status, Type 0: Sem status -->
  <div class="filter-div pt-3 pb-3 d-flex">
    <div class="flex">
      <span class="p-input-icon-left mr-3">
        <input pInputText type="text" [(ngModel)]="searchText" class="searchInput"
          (ngModelChange)="filterItems(searchText)" placeholder="Pesquisar" field="search" />
      </span>
      <p-button icon="pi pi-search" [rounded]="true" severity="success" styleClass="p-button-search" />

    </div>
    <p-button label="Adicionar Cidade" (onClick)="resetForm(); addUserDiag = true; edit = false;"
      icon="pi pi-plus-circle" class="add-button ms-auto p-button-success" iconPos="left"></p-button>
  </div>
  <div class="table-div">
    <app-generic-table (selectedData)="updateUser($event)" *ngIf="operacoesData.length > 0 && showComponent; else empty"
      [item]="{colunas: colunas, data: searchText && searchText.length > 0 ? filteredItems : operacoesData, type: 1, config: {disableSort: true, fields: fields}}"></app-generic-table>
  </div>
</section>

<p-dialog [closable]="false" header="{{edit ? 'Editar Operação' : 'Nova Operação'}}" styleClass="modalUserForm"
  [(visible)]="addUserDiag" [modal]="true" [draggable]="false" [resizable]="false">
  <form class="addUserForm" action="" [formGroup]="addUserForm">
    <div class="row">
      <div class="col-md-6 col-xxl-4 field">
        <label class="form-label d-block">Nome da Operação</label>
        <input placeholder="Nome da Cidade" formControlName="nome" type="text" pInputText />
      </div>

      <div class="col-md-6 col-xxl-4 field">
        <label class="form-label d-block">CPNJ</label>
        <p-inputMask styleClass="maskHolder" [style]="{'width':'100%'}" class="border-10" mask="99.999.999/9999-99"
          [autoClear]="false" formControlName="cnpj" placeholder="00.000.000/0000-00"></p-inputMask>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 field">
        <label class="form-label d-block">Valor Hora (carro)</label>
        <input (input)="onValueChange($event)" placeholder="R$" formControlName="valorHoraCarro" type="number"
          pInputText />
      </div>
      <div class="col-lg-2 field">
        <label class="form-label d-block">Valor Hora (moto)</label>
        <input (input)="onValueChange($event)" placeholder="R$" formControlName="valorHoraMoto" type="number"
          pInputText />
      </div>
      <div class="col-lg-2 field">
        <label class="form-label d-block">Período Mínimo</label>
        <input (input)="onValueChange($event)" placeholder="Minutos" formControlName="periodoMinimo" type="number"
          pInputText />
      </div>

      <div class="col-lg-2 field">
        <label class="form-label d-block">Período Médio</label>
        <input (input)="onValueChange($event)" placeholder="Minutos" formControlName="periodoMedio1" type="number"
          pInputText />
      </div>

      <div class="col-lg-2 field">
        <label class="form-label d-block">Período Médio 2</label>
        <input (input)="onValueChange($event)" placeholder="Minutos" formControlName="periodoMedio2" type="number"
          pInputText />
      </div>
      <div class="col-lg-2">
        <label class="form-label d-block">Período Máximo</label>
        <input (input)="onValueChange($event)" placeholder="Minutos" formControlName="periodoMaximo" type="number"
          pInputText />
      </div>
    </div>


    <div class="row">
      <div class="col-lg-2 field">
        <label class="form-label d-block">Perc. Outorga</label>
        <input (input)="onValueChange($event)" placeholder="%" min="0" formControlName="percRemuneracao" type="number"
          pInputText />
      </div>

      <div class="col-md-3 col-xl-2 field">
        <label class="form-label d-block">Perc. Admin</label>
        <input (input)="onValueChange($event)" placeholder="%" formControlName="percAdmin" type="number" pInputText />
      </div>

      <div class="col-md-3 col-xl-2 field">
        <label class="form-label d-block">Comissão PDV</label>
        <input (input)="onValueChange($event)" placeholder="%" formControlName="comissaoPDV" type="number" pInputText />
      </div>

      <div class="col-md-3 col-xl-2 field">
        <label class="form-label d-block">Vagas Carro</label>
        <input (input)="onValueChange($event)" placeholder="00" formControlName="totalVagasCarro" type="number"
          pInputText />
      </div>

      <div class="col-md-3 col-xl-2 field">
        <label class="form-label d-block">Vagas Moto</label>
        <input (input)="onValueChange($event)" placeholder="00" formControlName="totalVagasMoto" type="number"
          pInputText />
      </div>

      <div class="col-md-3 col-xl-2 field">
        <label class="form-label d-block">Total Vagas</label>
        @if(addUserForm.get('totalVagasCarro')){
        <b>{{addUserForm.get('totalVagasCarro')?.value + addUserForm.get('totalVagasMoto')?.value}}</b>
        }
        @else {
          <b>-</b>
        }
      </div>
    </div>

    <div class="row">
      <h6>Horários de Funcionamento</h6>
      <hr>

      <div formArrayName="horariosFuncionamento" class="row">
        <div (click)="getData()" *ngFor="let diaDaSemana of diasSemana.controls; let i = index;" class="col-lg-3">
          <div [formGroup]="diaDaSemana" class="box-date">
            <p>{{ diaDaSemana.value.diaDaSemana }}</p>
            <div class="d-flex align-items-center">
              <p-calendar formControlName="horaInicio" placeholder="00:00" dataType="string" appendTo="body"
                [timeOnly]="true" inputId="timeonly"></p-calendar>
              <span class="mr-2 ml-2">até</span>
              <p-calendar formControlName="horaFim" placeholder="00:00" dataType="string" appendTo="body"
                [timeOnly]="true" inputId="timeonly"></p-calendar>
              <p-inputSwitch formControlName="checked" inputId="binary"
                pTooltip="Clique aqui para que os horários de segunda-feira se repitam na semana"
                [style]="{marginLeft:  '0.5rem'}" *ngIf="diaDaSemana.value.diaDaSemana === 'Segunda'"
                (onChange)="onChangeCheckBox($event)"></p-inputSwitch>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- <div class="row">
      <h6>Comissão PDV</h6>
      <hr>
    </div>

    <div class="col-lg-2">
      <label class="form-label d-block">Percentual</label>
      <div class="flex align-items-center">
        <input placeholder="00" formControlName="percentual" type="number" pInputText />
        <span class="ml-2">%</span>
      </div>
    </div> -->
  </form>

  <ng-template pTemplate="footer">
    <button pButton pRipple type="button" label="Cancelar" (click)="addUserDiag = false; getOperacoes()"
      icon="pi pi-times" class="p-button-danger p-button-cancel"></button>
    <button pButton pRipple type="button" label="Salvar" (click)="createCidade()" [disabled]="addUserForm.invalid"
      class="p-button-save"></button>
  </ng-template>

</p-dialog>

<ng-template #empty>
  <div class="empty-table">
    <p class="text-center">Nenhum registro encontrado</p>
  </div>
</ng-template>