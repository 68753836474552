import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ConsultaPlacasService } from 'src/app/service/consulta-placas.service';
import { ExcelService } from 'src/app/service/excel.service';
import { FechamentoPosService } from 'src/app/service/fechamento-pos.service';
import { MultasService } from 'src/app/service/multas.service';
import { UserService } from 'src/app/service/user.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';

@Component({
  selector: 'app-avaliacao-monitores',
  templateUrl: './avaliacao-monitores.component.html',
  styleUrls: ['./avaliacao-monitores.component.css']
})
export class AvaliacaoMonitoresComponent {
  public addUserDiag: boolean = false;
  public userType: any = 'cliente';
  colunas: string[] = ["Rotas", "Vagas Carro", "Vagas Moto", "Cód. Usuário", "Nome", "Venda Total", "Índice de Desempenho"];
  fields = ['rota', 'vagaCarro', 'vagaMoto', 'codUser', 'nome', 'vendaTotal', 'indiceDesempenho', 'id'];
  dataInicio = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  dataFim = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  public multasData: any[] = [];
  filteredItems: any;
  showComponent: boolean = true;
  searchText: string;
  page: number = 1;
  perPage: number = 10;
  agentes: any[] = [];
  selectedAgentes: any;
  totalItems: number = 0;
  userData: never[];
  rotas: any;
  valorTotal: any;

  constructor(
    private readonly multaService: 
    MultasService, private loading: 
    LoadingService, private messageService: 
    MessageService, private route: 
    ActivatedRoute, private consultaPlacasService: 
    ConsultaPlacasService, 
    private excel: ExcelService,
    private readonly userService: UserService, 
    private fechamentoPosService: FechamentoPosService) { }

  ngOnInit(): void {
    // this.getMulta();
    this.loading.showLoading();
    this.route.params.subscribe((params: Params) => {
      this.page = params['page'] ? parseInt(params['page'], 10) : 1;
      this.perPage = params['perPage'] ? parseInt(params['perPage'], 10) : 10;
    });
    this.getRotas();
    this.fechamentoPosService.getDropdownFechamentoPos(localStorage.getItem("idCidade") as string).then((data: any) => {
      this.agentes = data;
    }).catch((error) => {
      this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar agentes!' });
      this.loading.hideLoading();
    });
  }

  changePage(page: any) {
    this.page = page.page + 1;
    this.getRotas();
  }

  getRotas(){
    this.rotas = [];
    this.loading.showLoading();
    let queryParams = {
      inicio: this.dataInicio,
      final: this.dataFim,
      page: 1,
      perPage: 1000,
      cidade: localStorage.getItem("idCidade")
    } as any;

    if(this.selectedAgentes){
      queryParams['operadorPOS'] = this.selectedAgentes;
    }
    

    this.consultaPlacasService.getRotas(queryParams).then((rotas: any) => {
      console.log("🚀 ~ AvaliacaoMonitoresComponent ~ this.consultaPlacasService.getRotas ~ rotas:", rotas)
      this.rotas = rotas.data.rotas;
      this.valorTotal = rotas.data.valorTotal;
      this.loading.hideLoading();
    })
  }

  filterItems(searchText: string) {
    this.refresh();
    this.filteredItems = this.multasData.filter((item: any) => {
      return Object.keys(item)
        .filter(key => key === 'cpf')
        .some(key => item[key].toString().toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  export() {
    const mappedData = this.rotas.map((item: any) => {
      return {
        "Rotas": item.rota,
        "Vagas Carro": item.vagaCarro,
        "Vagas Moto": item.vagaMoto,
        "Cód. Usuário": item.codUser,
        "Nome": item.nome,
        "Venda Total": item.vendaTotal,
        "Índice de Desempenho": item.indiceDesempenho,
      };
    });
    this.excel.exportAsExcelFile(mappedData, 'infracoes');
  }

  refresh() {
    // Altera o valor da propriedade para recriar o componente
    this.showComponent = false;
    setTimeout(() => this.showComponent = true);
  }
}
