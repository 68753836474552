import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CreditoService } from 'src/app/service/credito.service';
import { ExcelService } from 'src/app/service/excel.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';

@Component({
  selector: 'app-consulta-de-creditos',
  templateUrl: './consulta-de-creditos.component.html',
  styleUrls: ['./consulta-de-creditos.component.scss']
})
export class ConsultaDeCreditosComponent implements OnInit {

  public addUserDiag: boolean = false;
  public userType: any = 'cliente';
  colunas: string[] = ["Nome / Razão Social", "Identificador", "Email", "Saldo", "Tipo", "Forma de Pagto", "Dt de Compra", "Vencimento", "Central de Vendas"];
  fields = ['nome', 'cpf', 'email', 'saldo', 'tipo', 'formaPagamento', 'compra', 'vencimento', 'canalVendas'];

  public creditosData: any[] = [];
  filteredItems: any;
  showComponent: boolean = true;
  searchText: string;

  page: number = 1;
  perPage: number = 10;
  totalItems: number = 0;

  constructor(private readonly creditoService: CreditoService, private loading: LoadingService, private messageService: MessageService, private excel: ExcelService, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    // this.getCredito();
  }

  getCredito(){
    this.loading.showLoading();
    this.creditosData = [];
    this.creditoService.getCredito(this.searchText, this.page, this.perPage).then((res: any) => {
      this.creditosData = res.creditos;
      this.totalItems = res.total;
      this.loading.hideLoading();
    }
    ).catch((err) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao carregar créditos!', detail: err });
      this.loading.hideLoading();
    });
  }


  async export(){
    this.loading.showLoading();
    const allCredtios = (await this.creditoService.getCredito(this.searchText, this.page, this.totalItems) as any).creditos;
    const mappedData = allCredtios.map((item: any) => {
      return {
        "Nome / Razão Social": item.nome,
        "Identificador": item.cpf,
        "Email": item.email,
        "Saldo": item.saldo,
        "Tipo": item.tipo,
        "Forma de Pagto": item.formaPagamento,
        "Dt de Compra": item.compra,
        "Vencimento": item.vencimento,
        "Central de Vendas": item.canalVendas
      };
    });
    this.loading.hideLoading();
    this.excel.exportAsExcelFile(mappedData, 'creditos');
  }

  filterItems(searchText: string) {
    this.refresh();
    this.filteredItems = this.creditosData.filter((item: any) => {
      return Object.keys(item)
        .filter(key => key === 'cpf')
        .some(key => item[key].toString().toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  changePage(page: any) {
    this.page = page.page + 1;
    this.getCredito();
  }

  refresh() {
    // Altera o valor da propriedade para recriar o componente
    this.showComponent = false;
    setTimeout(() => this.showComponent = true);
  }

}
