import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';
import { MessageService } from 'primeng/api';
import { ExcelService } from 'src/app/service/excel.service';
import { CreditoService } from 'src/app/service/credito.service';

interface Venda {
  name: string;
  value?: number | string;
}

@Component({
  selector: 'app-vendas',
  templateUrl: './vendas.component.html',
  styleUrls: ['./vendas.component.scss']
})
export class VendasComponent implements OnInit {
  dataInicio = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  dataFim = new Date().toISOString().replace(/ /g, "-").split("T")[0];

  vendas: Venda[];
  canalDeVendas: any;
  meiosDePagamento: any;
  selectedVendas: Venda;
  listLoaded: boolean = false;
  dataLoaded: boolean = false;

  dataVendas: any[] = [];

  colunas: string[] = ["Identificador", "Data", "Horário", "Canal de Vendas", "Meio de Pagto", "Valor"];
  fields = ['nome', 'data', 'horario', 'canalVendas', 'formaPagamento', 'valor', 'id'];

  page: number = 1;
  perPage: number = 10;
  totalItems: number = 0;

  constructor(private _fb: FormBuilder, private creditoService: CreditoService, private messageService: MessageService, private loading: LoadingService, private excel: ExcelService) {
    this.vendas = [
      { name: 'Monitor', value: 2 },
      { name: 'PDV', value: 4 },
      { name: 'APP', value: 3 },
      { name: 'Legado', value: "0" },
      { name: 'Todos', value: undefined }
    ]
  }

  ngOnInit(): void {
    this.dataVendas = [];
    this.loading.showLoading();
    this.getHistoricoVendas();
    this.dataLoaded = false;
    this.creditoService.getVendas(new Date(this.dataInicio).toISOString(), new Date(this.dataFim).toISOString(), this.selectedVendas ? this.selectedVendas : undefined, this.page, this.perPage).then((data: any) => {
      // this.loading.hideLoading();
      this.dataVendas = data.DATA.creditos;
      this.totalItems = data.DATA.total;
      this.dataLoaded = true;
      this.checkLoad();
      // this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Vendas carregadas com sucesso!' });
    }).catch((error) => {
      this.loading.hideLoading();
      this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar vendas!' });
    });

  }

  changePage(page: any) {
    this.page = page.page + 1;
    this.ngOnInit();
  }

  async export() {
    this.loading.showLoading();
    // Mapeia os dados para a estrutura desejada
    const allVendas = (await this.creditoService.getVendas(new Date(this.dataInicio).toISOString(), new Date(this.dataFim).toISOString(), this.selectedVendas ? this.selectedVendas : undefined, 1, this.totalItems) as any).DATA.creditos;
    const mappedData = allVendas.map((item: any) => {
      return {
        "Identificador": item.nome,
        "Data": item.data,
        "Horário": item.horario,
        "Canal de Vendas": item.canalVendas,
        "Meio de Pagto": item.formaPagamento,
        "Valor": item.valor,
      };
    });
  
    // Exporta os dados mapeados
    this.loading.hideLoading();
    this.excel.exportAsExcelFile(mappedData, 'vendas');
  }

  checkLoad() {
    if (this.listLoaded && this.dataLoaded) {
      this.loading.hideLoading();
    }
  }


  getHistoricoVendas() {
    this.listLoaded = false;
    this.loading.showLoading();
    this.creditoService.getHistoricoVendas(new Date(this.dataInicio).toISOString(), new Date(this.dataFim).toISOString(), this.selectedVendas ? this.selectedVendas : 1).subscribe({
      next: (result) => {
        this.canalDeVendas = result.DATA.data.canalVendas;
        this.meiosDePagamento = result.DATA.data.meiosDePagamento;
      },
      error: (error) => {
        console.log(error);
        this.loading.hideLoading();
        this.messageService.add({ severity: 'error', summary: 'Erro ao carregar cidades!', detail: error });
      },
      complete: () => {
        this.listLoaded = true;
        this.checkLoad();
      }
    })
  }
}
