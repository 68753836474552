import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { EstacionamentoService } from 'src/app/service/estacionamento.service';
import { ExcelService } from 'src/app/service/excel.service';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';

@Component({
  selector: 'app-estacionamentos',
  templateUrl: './estacionamentos.component.html',
  styleUrls: ['./estacionamentos.component.scss']
})
export class EstacionamentosComponent implements OnInit {
  searchText: string;
  filteredItems: any;
  showComponent: boolean = true;
  listLoaded: boolean = false;
  dataLoaded: boolean = false;

  public addUserDiag: boolean = false;
  public userType: any;
  colunas: string[] = ["Código do usuário", "Código do estacionamento", "Data", "Início", "Término", "Placa", "Tipo", "Comprovante"];
  fields = ['idUsuario', 'cod', 'dataCompra', 'periodoInicial', 'periodoFinal', 'placa', 'tipoVeiculo', 'comprovante'];

  estacionamentoData: any[] = [];
  estacionamentoHistorico: any;
  getUserFiltro() {
    console.log(this.userType);
  }
  dataInicio = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  dataFim = new Date().toISOString().replace(/ /g, "-").split("T")[0];

  page: number = 1;
  perPage: number = 10;
  totalItems: number = 0;

  constructor(private readonly estacionamentoService: EstacionamentoService, private messageService: MessageService, private loading: LoadingService, private excel: ExcelService) { }

  ngOnInit(): void {
    this.page = 1;
    this.getEstacionamento();
    this.getHistoricoEstacionamento();
    this.refresh();
  }

  changePage(page: any) {
    this.page = page.page + 1;
    this.getEstacionamento();
  }

  getEstacionamento() {
    this.dataLoaded = false;
    this.loading.showLoading();
    this.estacionamentoData = [];
    this.estacionamentoService.getEstacionamentos(this.dataInicio, this.dataFim, this.page, this.perPage, this.searchText).then((res: any) => {
      this.estacionamentoData = res.DATA.data.estacionamentos;
      for (const data of this.estacionamentoData) {
        if (data.comprovante.toString() == 'SMS e E-mail') {
          data.comprovante = "SMS"
        }
        data.cod = data.id;
      }
      this.totalItems = res.DATA.data.total;
      // this.loading.hideLoading();
      this.dataLoaded = true;
      this.checkLoad();
    }
    ).catch((err) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao carregar estacionamentos!', detail: err });
      this.loading.hideLoading();
    });
  }

  async export() {
    this.loading.showLoading();
    const allEstacionamentos: any = (await this.estacionamentoService.getEstacionamentos(this.dataInicio, this.dataFim, 1, this.totalItems, this.searchText) as any).DATA.data.estacionamentos
    const mappedData = allEstacionamentos.map((item:any) => {
      return {
        "Código do usuário": item.idUsuario,
        "Código do estacionamento": item.cod,
        "Data": item.dataCompra,
        "Início": item.periodoInicial,
        "Término": item.periodoFinal,
        "Placa": item.placa,
        "Tipo": item.tipoVeiculo,
        "Comprovante": item.comprovante,
      };
    });
    this.loading.hideLoading();
    this.excel.exportAsExcelFile(mappedData, 'estacionamentos');
  }

  getHistoricoEstacionamento() {
    this.listLoaded = false;
    this.loading.showLoading();
    this.estacionamentoService.getEstacionamentosQuadros(this.dataInicio, this.dataFim).subscribe({
      next: (result) => {
        this.estacionamentoHistorico = result.DATA.data
      },
      error: (error) => {
        this.loading.hideLoading();
        console.log(error)
      },
      complete: () => {
        this.listLoaded = true;
        this.checkLoad();
        // this.loading.hideLoading();
      }
    })
  }

  checkLoad() {
    if (this.listLoaded && this.dataLoaded) {
      this.loading.hideLoading();
    }
  }

  filterItems(searchText: string) {
    this.refresh();
    this.filteredItems = this.estacionamentoData.filter((item: any) => {
      return Object.keys(item)
        .filter(key => key == 'placa')
        .some(key => item[key].toString().toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  refresh() {
    // Altera o valor da propriedade para recriar o componente
    this.showComponent = false;
    setTimeout(() => this.showComponent = true, 1000);
  }
}
