<section class="position-relative pt-5">
  <div class="filter-div pt-3 pb-3 d-flex">
    <div class="me-3">
      <b class="me-3 titleCalendar">De</b>
      <input type="date" [(ngModel)]="dataInicio">
    </div>
    <div class="me-3">
      <b class="me-3 titleCalendar">Até</b>
      <input type="date" [(ngModel)]="dataFim">

    </div>
    <!-- Filtrar -->
    <button pButton pRipple type="button" (click)="ngOnInit()" label="Selecionar período" class="p-button-success filter-button"></button>

    <!-- Adicionar Usuário -->
    <!-- <p-button label="Exportar (.csv)" class="add-button ms-auto"></p-button> -->
  </div>

  <div class="px-0 py-5">
    <div class="grid text-center">
      <div class="md:col-4 xxl:col-4">
        <div class="grid gap-2 justify-content-center">
          <div class="md:col-9 col-title">
            <span>Receita Auferida no Período por Plataforma de Vendas</span>
          </div>

          <div class="md:col-12 linha"></div>

          <div class="md:col-4 col-first">
            <span>APP</span>
          </div>

          <div class="md:col-5 col-first">
            <span>{{totalVendas?.app}}</span>
          </div>

          <div class="md:col-4 col-first">
            <span>Monitores</span>
          </div>

          <div class="md:col-5 col-first">
            <span>{{totalVendas?.monitores}}</span>
          </div>

          <div class="md:col-4 col-first">
            <span>PDV's</span>
          </div>

          <div class="md:col-5 col-first">
            <span>{{totalVendas?.pdv}}</span>
          </div>

          <div class="md:col-4  receita">
            <span>Receita Total</span>
          </div>

          <div class="md:col-5 col-result">
            <span>{{totalVendas?.geral}}</span>
          </div>

        </div>
      </div>

      <div class="md:col-4 xxl:col-4">
        <div class="grid gap-3 justify-content-center ">
          <div class="md:col-9 col-title">
            <span>Taxa de Ocupação (%)</span>
          </div>

          <div class="md:col-12 linha" [style]="{'padding': '0.5rem'}"></div>

          <div class="md:col-10">
            <div class="chart-container">
              <p-chart type="bar" [data]="basicData" [options]="basicOptions" />
            </div>

          </div>

        </div>
      </div>

      <div class="md:col-4 xxl:col-4">
        <div class="grid gap-2 justify-content-center ">
          <div class="md:col-10 col-title">
            <span>Métricas e Resultados</span>
          </div>

          <div class="md:col-12 linha" [style]="{'margin': '1.5rem'}"></div>

          <div class="md:col-4 receita">
            <span>Taxa de Ocupação Média</span>
          </div>

          <div class="md:col-5 col-result">
            <span>{{estacionamentoHistorico?.indiceOcupacao}}%</span>
          </div>

          <div class="md:col-4 receita">
            <span>N° de Infrações Identificadas</span>

          </div>

          <div class="md:col-5 col-result">
            <span>{{totalItems}}</span>
          </div>
        </div>
      </div>

      <div class="md:col-12 lg:col-4 pt-6">
          <div class="grid gap-2 justify-content-center ">
          <div class="md:col-9 col-title">
            <span>Quantidade de Vendas</span>
          </div>

          <div class="md:col-12 linha" [style]="{'padding-bottom': '1rem'}"></div>

          <div class="md:col-10">
            <div class="chart-data">
              <p-chart type="bar" [data]="data" [options]="options" />
            </div>
          </div>
        </div>

      </div>

      <div class="md:col-12 lg:col-4 pt-6">
        <div class="grid justify-content-center">
          <div class="md:col-10 col-title">
            <span>Vagas Operacionais</span>
          </div>

          <div class="md:col-12 linha" [style]="{'margin': '1.5rem'}"></div>

         <div class="flex flex-column col-10 mx-auto colums-vagas">
          <div class="grid mb-4">
            <div class="md:col-12 lg:col-5">
              <span>Carros</span>
            </div>

            <div class="md:col-12 lg:col-5 operacionais">
              <span class="text-white">{{cidade?.totalVagasCarro}}</span>
            </div>
          </div>

          <div class="grid mb-4">
            <div class="md:col-12 lg:col-5">
              <span>Motos</span>
            </div>

            <div class="md:col-12 lg:col-5 col-total">
              <span>{{cidade?.totalVagasMoto}}</span>
            </div>

          </div>

          <div class="grid ">
            <div class="md:col-12 lg:col-5">
              <span >Total</span>
            </div>

            <div class="md:col-12 lg:col-5 operacionais">
              <span class="text-white">{{cidade?.totalVagasMoto + cidade?.totalVagasCarro}}</span>
            </div>
          </div>
         </div>




        </div>
      </div>



    </div>
  </div>
</section>
