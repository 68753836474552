import { FormBuilder } from '@angular/forms';
import { Component } from '@angular/core';
import { LoadingService } from 'src/app/shared/loading/loading.service.component';
import { FechamentoPosService } from 'src/app/service/fechamento-pos.service';
import { FechamentoPdvService } from 'src/app/service/fechamento-pdv.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { copyFileSync } from 'fs';
import { Dropdown } from 'primeng/dropdown';
import { ExcelService } from 'src/app/service/excel.service';

@Component({
  selector: 'app-fechamento',
  templateUrl: './fechamento.component.html',
  styleUrls: ['./fechamento.component.scss']
})
export class FechamentoComponent {
  dataInicio = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  dataFim = new Date().toISOString().replace(/ /g, "-").split("T")[0];
  typeFechamento: number;
  selectedVendas: any;
  dadosPOS: any = [];
  dadosPDV: any = [];
  calculoPeriodoPOS: any;
  calculoPeriodoPDV: any


  constructor(
    private _fb: FormBuilder,
    private loading: LoadingService,
    private fechamentoPosService: FechamentoPosService,
    private messageService: MessageService,
    private fechamentoPdvService: FechamentoPdvService,
    private excel: ExcelService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.getRoute();
  }
  getDropdownFechamentosPOS() {
    this.dadosPOS = [];
    this.loading.showLoading();
    this.fechamentoPosService.getDropdownFechamentoPos(localStorage.getItem("idCidade") as string).then((res: any) => {
      this.dadosPOS = res;
      this.getCalculoPeriodoPos();
      this.typeFechamento = 0;
      this.loading.hideLoading();
    }
    ).catch((err) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao carregar estacionamentos!', detail: err });
      this.loading.hideLoading();
    })
  }

  getDropdownFechamentosPDV() {
    this.dadosPDV = [];
    this.loading.showLoading();
    this.fechamentoPdvService.getDropdownFechamentoPdv(localStorage.getItem("idCidade") as string).then((res: any) => {
      this.dadosPDV = res;
      this.getCalculoPeriodoPdv();
      this.typeFechamento = 1;
      this.loading.hideLoading();
    }
    ).catch((err) => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao carregar estacionamentos!', detail: err });
      this.loading.hideLoading();
    });
  }

  getCalculoPeriodoPos() {
    this.loading.showLoading();
    this.fechamentoPosService.getCalculoPeriodoPos(new Date(this.dataInicio).toISOString().slice(0, 10), new Date(this.dataFim).toISOString().slice(0, 10), this.selectedVendas).subscribe({
      next: (result) => {
        this.calculoPeriodoPOS = result.DATA;
        this.calculoPeriodoPOS.percentual = 0.0;
        this.loading.hideLoading();
      },
      error: (error) => {
        console.log(error);
        this.loading.hideLoading();
      },
      complete: () => {
        console.log('ok');
      }
    })
  }
  getCalculoPeriodoPdv() {
    this.loading.showLoading();
    this.fechamentoPdvService.getCalculoPeriodoPdv(new Date(this.dataInicio).toISOString().slice(0, 10), new Date(this.dataFim).toISOString().slice(0, 10), this.selectedVendas).subscribe({
      next: (result) => {
        this.calculoPeriodoPDV = result.DATA;
        this.loading.hideLoading();
      },
      error: (error) => {
        console.log(error);
        this.loading.hideLoading();
      },
      complete: () => {
        this.loading.hideLoading();
        console.log('ok');
      }
    })
  }

  getRoute() {
    const routerState: any = this.router.routerState.snapshot.url;
    switch (routerState) {
      case '/sistema/fechamento/monitores':
        this.getDropdownFechamentosPOS()
        break;
      case '/sistema/fechamento/pdv':
        this.getDropdownFechamentosPDV()
        break;
      default:
        console.log("erro");
        break;
    }
  }

  liquidValue(total: number, percent: any) {
    if (typeof percent === 'string') percent = Number(percent.replace(" %", "").replace(" %", "").replace("_", "").replace("_", "").replace("_", "").replace("_", ""))
    let result = 0;
    if (percent > 100.0) {
      percent = 100.0;
      this.calculoPeriodoPOS.percentual = 100.0;
      this.messageService.add({ severity: "info", summary: "Valor inválido" })
    }
    percent = percent / 100;
    result = total * percent;
    this.calculoPeriodoPOS.valorLiquido = result;
  }

  liquidValuePDV(total: number, percent: any) {
    if (typeof percent === 'string') percent = Number(percent.replace(" %", "").replace(" %", "").replace("_", "").replace("_", "").replace("_", "").replace("_", ""))
    let result = 0;
    if (percent > 100.0) {
      percent = 100.0;
      this.calculoPeriodoPOS.percentual = 100.0;
      this.messageService.add({ severity: "info", summary: "Valor inválido" })
    }
    percent = percent / 100;
    result = total * percent;
    return result;
  }

  export() {
    this.excel.exportAsExcelFile(this.redirect(), this.typeFechamento === 0 ? 'fechamentos_pos' : 'fechamentos_pdv');
  }

  redirect(): any[] {
    const routerState: any = this.router.routerState.snapshot.url;
    if (routerState === '/sistema/fechamento/pdv') {
      return this.getDatasToExcelPDV()
    }
    return this.getDatasToExcelPOS();
  }

  getDatasToExcelPDV(): any[] {
    const newArray1: any[] = [];
    const newArray2: any[] = [];
    const newArray3: any[] = [];
    const finalArray: any[] = [];
    Object.entries(this.calculoPeriodoPDV).forEach(([key, val]) => {
      if (key !== "percentual" && key !== "valorLiquido")
        newArray1.push({
          "Forma de Pagamento": key,
          "Valor": val,
        })
    })

    Object.entries(this.calculoPeriodoPDV).forEach(([key, val]) => {
      if (key === "percentual")
        newArray2.push({
          "Percentual": val
        })
    })

    Object.entries(this.calculoPeriodoPDV).forEach(([key, val]) => {
      if (key === "valorLiquido")
        newArray3.push({
          "Comissão": this.liquidValuePDV(this.calculoPeriodoPDV?.total, this.calculoPeriodoPDV.percentual)
        })
    })

    const length = Math.max(...[newArray1.length, newArray2.length, newArray3.length])
    for (let index = 0; index < length; index++) {
      const element1 = newArray1[index];
      const element2 = newArray2[index];
      const element3 = newArray3[index];
      if (element1 && element2 && element3) {
        finalArray.push(Object.assign(element1, element2, element3))
      } else if (element1 && element2 && !element3) {
        finalArray.push(Object.assign(element1, element2))
      } else if (element1 && !element2 && element3) {
        finalArray.push(Object.assign(element1, element3))
      } else if (!element1 && element2 && element3) {
        finalArray.push(Object.assign(element3, element2))
      } else if (element1 && !element2 && !element3) {
        finalArray.push(element1)
      } else if (!element1 && element2 && !element3) {
        finalArray.push(element2)
      } else if (!element1 && !element2 && element3) {
        finalArray.push(element3)
      }
    }
    return finalArray;
  }

  getDatasToExcelPOS(): any[] {
    const newArray1: any[] = [];
    const newArray2: any[] = [];
    const newArray3: any[] = [];
    const finalArray: any[] = [];
    Object.entries(this.calculoPeriodoPOS).forEach(([key, val]) => {
      if (key !== "percentual" && key !== "valorLiquido")
        newArray1.push({
          "Forma de Pagamento": key,
          "Valor": val,
        })
    })

    Object.entries(this.calculoPeriodoPOS).forEach(([key, val]) => {
      if (key === "percentual")
        newArray2.push({
          "Percentual": val
        })
    })

    Object.entries(this.calculoPeriodoPOS).forEach(([key, val]) => {
      if (key === "valorLiquido")
        newArray3.push({
          "Comissão": val
        })
    })

    const length = Math.max(...[newArray1.length, newArray2.length, newArray3.length])
    for (let index = 0; index < length; index++) {
      const element1 = newArray1[index];
      const element2 = newArray2[index];
      const element3 = newArray3[index];
      if (element1 && element2 && element3) {
        finalArray.push(Object.assign(element1, element2, element3))
      } else if (element1 && element2 && !element3) {
        finalArray.push(Object.assign(element1, element2))
      } else if (element1 && !element2 && element3) {
        finalArray.push(Object.assign(element1, element3))
      } else if (!element1 && element2 && element3) {
        finalArray.push(Object.assign(element3, element2))
      } else if (element1 && !element2 && !element3) {
        finalArray.push(element1)
      } else if (!element1 && element2 && !element3) {
        finalArray.push(element2)
      } else if (!element1 && !element2 && element3) {
        finalArray.push(element3)
      }
    }
    return finalArray;
  }
}
