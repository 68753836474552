import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreditoService {

  base_url = `${environment.baseUrl}`;
  constructor(private http: HttpClient) { }

  getCredito(documento?: any, page?: number, perPage?: number) {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    let link = `${this.base_url}/v1/admin/creditos`
    if (localStorage.getItem('idCidade')) {
      link += `?cidade=${localStorage.getItem('idCidade')}`
    }
    if (documento) {
      link += `&documento=${documento}`
    }
    if (page) {
      link += `&page=${page}&perPage=${perPage}&pagination=true`
    }

    return new Promise(async (resolve, reject) => {
      this.http
        .get(link, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  getCreditoById(id: string) {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };

    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.base_url}/v1/creditoRecibo/${id}`, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  getVendas(de: any, ate: any, canal: any, page: any, perPage: any) {
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    let link = `${this.base_url}/v1/admin/vendas`
    if (localStorage.getItem('idCidade')) {
      link += `?cidade=${localStorage.getItem('idCidade')}`
    }
    if (de && ate) {
      link += `&de=${de}&ate=${ate}`;
    }
    if (canal) {
      link += `&canal=${canal}`;
    }
    if (page) {
      link += `&page=${page}&perPage=${perPage}&pagination=true`;
    }
    return new Promise(async (resolve, reject) => {
      this.http
        .get(link, httpOptions).subscribe(
          async (data: any) => {
            resolve(data);
          },
          (err) => {
            reject(err)
          });
    });
  }

  getCreditoLogByAgente(id?: string, date?: string) {
    const query: string = `?date=${date}&id=${id === undefined ? '' : id}`;
    const accessToken = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${this.base_url}/v1/creditoLog${query}`, httpOptions).subscribe(
          async (data: any) => {
            resolve(data.DATA);
          },
          (err) => {
            reject(err)
          });
    });
  }

  getHistoricoVendas(dateBegin?: string, dateEnd?: string, canal?: any): Observable<any> {
    const accessToken = localStorage.getItem('token');
    const idCidade = localStorage.getItem('idCidade');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      })
    };
    return this.http.get<any>(`${this.base_url}/v1/admin/historico-vendas-somatorio?cidade=${idCidade}&inicio=${dateBegin}&final=${dateEnd}&canal=${canal}`, httpOptions).pipe(take(1))
  }
}
